import Button from 'components/Forms/Button';
import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import Table from 'components/Table';
import InputFilter from 'components/Table/InputFilter';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { userStore } from 'stores/UserStore';

const UsersTable = observer(() => {
    const [filter, setFilter] = useState<any>({});
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        userStore.getUsers();

        return () => {
            userStore.resetStore();
        }
    }, [])

    const updateFilter = (key: string, value: string) => {
        const f = filter;
        f[key] = value;
        setFilter(f);


        userStore.resetStore();
        userStore.getUsers(f);
    }

    const users = userStore.users?.items || [];

    return (
        <>
            <Table
                onRowClicked={(item) => navigate(generatePath(superAdminRoutes.pageUserDetails, { id: item.id }))}
                defaultSortLabel='name'
                data={users}
                render={[
                    {
                        label: t('name'),
                        cell: (item) => item.name,
                        appendToLabel: <InputFilter placeholder={`${t('search')}...`} onChange={(val) => updateFilter('byName', val)} />
                    },
                    {
                        label: t('email'),
                        cell: (item) => item.email,
                        appendToLabel: <InputFilter placeholder={`${t('search')}...`} onChange={(val) => updateFilter('byEmail', val)} />
                    }
                ]}
            />
            {
                userStore.users?.pagination.hasNextPage &&
                <div className='text-center mt-4'>
                    <Button
                        type='button'
                        label={`${t('loadMore')}...`}
                        appearance='secondary'
                        onClick={() => userStore.getUsers()}
                    />
                </div>
            }
        </>
    )
})

export default UsersTable;