import { router, routes } from 'components/Routes';
import { toast } from "react-toastify";
import { BaseRequest } from './BaseRequest';


const req = new BaseRequest({
    baseUrl: process.env.REACT_APP_API_BASE_URL as string,
    clientKey: process.env.REACT_APP_CLIENT_KEY as string,
    errorResponse: (error) => {
        if (error.response?.status) {
            switch (error.response.status) {
                case 401:
                case 403:
                    router.navigate(routes.pageLogin);
                    break;
                case 400:
                    toast(error.response.data as string, { type: 'error' });
                    break;
            }
        }
        return Promise.reject(error);
    }
})

const request = req.request;
export default request;
