import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import SchemaForm from 'components/SchemaForm';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formDetailsStore } from 'stores/FormDetailsStore';
import { SaveFormInterface } from 'types/SaveFormInterface';

const PageFormCreate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = async (data: SaveFormInterface) => {
        try {
            console.log(data)
            data.isTemplate = true;

            await formDetailsStore.createForm(data);
            toast(t('saved'), { type: 'success' });

            navigate(generatePath(superAdminRoutes.pageFront))
        }
        catch (error) {
            toast(error as any, { type: 'error' });
        }
    }

    return (
        <SchemaForm
            title={t('createForm')}
            onSubmit={onSubmit}
            canSaveCategory
            defaultValues={{
                area: false,
                areaRequired: false,
                section: false,
                sectionRequired: false,
                preliminaryNoteField: false,
                smileys: false,
                notes: false,
                photos: false,
                photosGPS: false,
                emails: '',
                title: '',
                sections: [
                    {
                        title: '',
                        description: '',
                        items: [{
                            title: ''
                        }]
                    }
                ]
            }}
        />
    )
}

export default PageFormCreate;