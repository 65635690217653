import Button from 'components/Forms/Button';
import CategoriesTable from 'components/SuperAdmin/CategoriesTable'
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { superadminCategoriesStore } from 'stores/SuperadminCategoriesStore';

const PageCategories = observer(() => {
    const { t } = useTranslation();

    const onCreateCategory = async () => {
        try {
            const catName = window.prompt(t('name')!, "");
            if (!catName) return;
    
            await superadminCategoriesStore.createCategory({ name: catName});
            toast(t('saved'), { type: 'success' });
            
        }
        catch(error) {
            toast(error as any, { type: 'error' });
        }
    }

    return (
        <div>
            <div className='text-right pt-1 pb-3'>
                <Button
                    type='button'
                    appearance='primary'
                    label={t('createNew')}
                    onClick={onCreateCategory}
                />
            </div>
            <CategoriesTable />
        </div>
    )
})

export default PageCategories;