import Error from '../Error';

interface Props {
    name: string,
    label?: string | React.ReactNode,
    className?: string
}

const Label = (props: Props) => {
    if (!props.label) return null;

    return (
        <span>
            <label
                className='text-sm font-medium mb-1 inline'
                id={props.name}
                htmlFor={props.name}>
                {props.label}
            </label>
            <Error name={props.name} />
        </span>
    )
}

export default Label;