export enum SchemaFormEnum {
    id = 'id',
    area = 'area',
    areaRequired = 'areaRequired',
    section = 'section',
    sectionRequired = 'sectionRequired',
    preliminaryNoteField = 'preliminaryNoteField',
    smileys = 'smileys',
    notes = 'notes',
    photos = 'photos',
    photosGPS = 'photosGPS',
    emails = 'emails',
    title = 'title',
    description = 'description',
    items = 'items',
    sections = 'sections',
    position = 'position',
    isDraft = 'isDraft',
    isDemoTemplate = 'isDemoTemplate',
    company = 'company',
    category = 'category'
}