import { action, makeObservable, observable } from 'mobx';
import { UserInterface } from 'types/UserInterface';
import { BaseStore } from './BaseStore';
import request from 'helpers/Request';
import { RoleEnum } from 'types/RoleEnum';

class CompanyUsersStore extends BaseStore {

    users: Array<UserInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            users: observable,

            resetStore: action,
            getUsers: action,
            deleteUser: action,
            updateUserRole: action
        })
    }

    resetStore = () => {
        this.users = [];
    }

    getUsers = (companyId: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`companies/${companyId}/users?pageSize=999&pageNumber=1`);
                if (res.data) {
                    const users = res.data.items;
                    this.users = users;
                    resolve(users);
                }
            }
            catch(error) {
                reject(error);
            }
        })
    }

    deleteUser = (companyId: string, userId: string) => {
        return this.delete(`companies/${companyId}/users/${userId}`, userId, this.users);
    }

    updateUserRole = (companyId: string, userId: string, role: RoleEnum) => {
        return this.put(`companies/${companyId}/users/${userId}`, {
            role: role
        }, 'users', true)
    }

}

export const companyUsersStore = new CompanyUsersStore();