import { buildQuery } from 'helpers/QueryHelper';
import { action, makeObservable, observable } from 'mobx';
import { PagingResultInterface } from 'types/PagingResultInterface';
import { UserInterface } from 'types/UserInterface';
import { BaseStore } from './BaseStore';

class UserStore extends BaseStore {

    users: PagingResultInterface<UserInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            users: observable,

            resetStore: action,
            getUsers: action
        })
    }

    resetStore = () => {
        this.users = null;
    }

    getUsers = (opts?: {
        byName?: string,
        byEmail?: string
    }) => {
        let params = {} as any;

        if (opts?.byName) {
            params['byName'] = opts.byName;
        }

        if (opts?.byEmail) {
            params['byEmail'] = opts.byEmail;
        }

        return this.getPaged(buildQuery('users', opts), this.users, 'users');
    }

}

export const userStore = new UserStore();