import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import HtmlEditor from 'components/HtmlEditor';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { informationStore } from 'stores/InformationStore';
import { languageStore } from 'stores/LanguageStore';
import { InformationInterface } from 'types/InformationInterface';

const ABOUT_TEXT = 'aboutText';
const GUIDE_TEXT = 'guideText';

interface OnSubmitInterface {
    [ABOUT_TEXT]: string,
    [GUIDE_TEXT]: string
}

const PageInformation = observer(() => {
    const { t } = useTranslation();

    const { language } = languageStore;
    useEffect(() => {
        informationStore.getInformation(language);

        return () => {
            informationStore.resetStore();
        }
    }, [language])

    const onSubmit = async (information: InformationInterface, data: OnSubmitInterface) => {
        information.aboutText = data[ABOUT_TEXT];
        information.guideText = data[GUIDE_TEXT];

        await informationStore.updateInformation(information);
        toast(t('saved'), { type: 'success' });
    }

    const information = informationStore.information;
    if (!information) return null;

    return (
        <div>
            <Form
                validation={(yup) => ({
                    [ABOUT_TEXT]: yup.string().required('*'),
                    [GUIDE_TEXT]: yup.string().required('*'),
                })}
                formOptions={{
                    defaultValues: {
                        [ABOUT_TEXT]: information.aboutText,
                        [GUIDE_TEXT]: information.guideText
                    }
                }}
                onSubmit={(data) => onSubmit(information, data)}>
                <h1 className='font-semibold'>{t('about')}</h1>
                <HtmlEditor
                    name={ABOUT_TEXT}
                    className='h-64 mt-2'
                />

                <h1 className='font-semibold mt-8'>{t('guide')}</h1>
                <HtmlEditor
                    name={GUIDE_TEXT}
                    className='h-64 mt-2'
                />

                <div className='my-4'>
                    <Button
                        type='submit'
                        appearance='primary'
                        label={t('save')}
                    />
                </div>
            </Form>
        </div>
    )

})

export default PageInformation;