import { routes } from 'components/Routes';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { authStore } from 'stores/AuthStore';
import Header from 'components/Header';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import SidebarMenu from 'components/SidebarMenu';
import MenuItem from 'components/SidebarMenu/MenuItem';
import { useTranslation } from 'react-i18next';
import { superAdminRoutes } from 'components/Routes/SuperAdmin';

const LoggedInWrapper = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const isLoggedIn = authStore.isLoggedIn;
    const { selectedCompany, loggedInAsSuperAdmin } = selectedCompanyStore;
    useEffect(() => {
        if (!isLoggedIn) {
            navigate(routes.pageLogin);
        }
        else if (!loggedInAsSuperAdmin) {
            navigate(routes.pageSelectUserCompany);
        }
    }, [isLoggedIn, navigate, selectedCompany, loggedInAsSuperAdmin])

    return (
        <div className='mx-auto max-w-screen-xl p-8 min-w-[800px]'>
            <Header />
            <div className='flex'>
                <div className=''>
                    <SidebarMenu>
                        <MenuItem
                            label={t('templates')}
                            onClick={() => navigate(superAdminRoutes.pageFront)}
                            isActive={pathname === superAdminRoutes.pageFront}
                        />
                        <MenuItem
                            label={t('categories')}
                            onClick={() => navigate(superAdminRoutes.pageCategories)}
                            isActive={pathname === superAdminRoutes.pageCategories}
                        />
                        <MenuItem
                            label={t('users')}
                            onClick={() => navigate(superAdminRoutes.pageUsers)}
                            isActive={pathname === superAdminRoutes.pageUsers}
                        />
                        <MenuItem
                            label={t('companies')}
                            onClick={() => navigate(superAdminRoutes.pageCompanies)}
                            isActive={pathname === superAdminRoutes.pageCompanies}
                        />
                        <MenuItem
                            label={t('information')}
                            onClick={() => navigate(superAdminRoutes.pageInformation)}
                            isActive={pathname === superAdminRoutes.pageInformation}
                        />
                    </SidebarMenu>
                </div>
                <div className='flex-1 bg-white ml-6 rounded p-4 shadow'>
                    <Outlet />
                </div>
            </div>


        </div>
    )

}

export default LoggedInWrapper;