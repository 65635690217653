import { routes } from 'components/Routes';
import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import SchemaForm from 'components/SchemaForm';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formDetailsStore } from 'stores/FormDetailsStore';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import { FormSettingEnum } from 'types/FormSettingEnum';
import { SaveFormInterface } from 'types/SaveFormInterface';

interface Props {
    canSaveCategory?: boolean;
}

const PageFormEdit = observer((props: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        formDetailsStore.getForm(id!);

        return () => {
            formDetailsStore.resetStore();
        }
    }, [])

    const navigateToFormsOverview = () => {
        if (selectedCompanyStore.loggedInAsSuperAdmin) {
            navigate(generatePath(superAdminRoutes.pageFront))
        }
        else {
            navigate(generatePath(routes.pageFront))
        }
    }

    const onSubmit = async (data: SaveFormInterface) => {

        try {

            if (selectedCompanyStore.loggedInAsSuperAdmin) {
                data.isTemplate = true;
                data.emailRecipients = [];
            }

            await formDetailsStore.updateForm(data);
            toast(t('saved'), { type: 'success' });

            navigateToFormsOverview();
        }
        catch (error) {
            toast(error as any, { type: 'error' });
        }
    }

    const onDelete = async (formId: string) => {
        if (!window.confirm(t('confirmFormDelete')!)) return;

        await formDetailsStore.deleteForm(formId);
        navigateToFormsOverview();
    }

    const form = formDetailsStore.form;
    if (!form) return null;

    return (
        <>
            <SchemaForm
                title={t('editForm')}
                onSubmit={onSubmit}
                canSaveCategory={props.canSaveCategory}
                defaultValues={{
                    id: form.id,
                    category: form.category?.id,
                    area: form.settingsAreaField > FormSettingEnum.OFF,
                    areaRequired: form.settingsAreaField === FormSettingEnum.REQUIRED,
                    section: form.settingsSectionField > FormSettingEnum.OFF,
                    sectionRequired: form.settingsSectionField === FormSettingEnum.REQUIRED,
                    preliminaryNoteField: form.settingsPreliminaryNoteField > FormSettingEnum.OFF,
                    smileys: form.settingsSectionSmileys > FormSettingEnum.OFF,
                    notes: form.settingsSectionNotes > FormSettingEnum.OFF,
                    photos: form.settingsSectionPhotos > FormSettingEnum.OFF,
                    isDemoTemplate: form.isDemoTemplate,
                    isDraft: form.isDraft,
                    photosGPS: form.settingsShowGPSCoordinates,
                    emails: form.emailRecipients?.join(','),
                    title: form.title,
                    sections: _.orderBy(form.sections, s => s.position).map((section) => ({
                        id: section.id,
                        title: section.title,
                        description: section.description,
                        items: _.orderBy(section.items, i => i.position).map((item) => ({
                            id: item.id,
                            title: item.title
                        }))
                    }))
                }}
            />
            <div className='mt-12 text-right'>
                <button 
                    className='underline text-gray-600'
                    onClick={() => onDelete(form.id!)}
                    type='button'>
                    {t('delete')} {t('form',)!.toLowerCase()}
                </button>
            </div>
        </>
    )
})

export default PageFormEdit;