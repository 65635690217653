import Button from 'components/Forms/Button';
import Input from 'components/Forms/Input';
import { useTranslation } from 'react-i18next';
import NotLoggedInWrapper from 'components/NotLoggedInWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from 'components/Forms/Form';
import { authStore } from 'stores/AuthStore';
import { routes } from 'components/Routes';

const EMAIL = 'email';
const NEW_PASSWORD = 'password';
const NEW_PASSWORD_REPEAT = 'password2';

const PageResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams<{ token: string }>();
    const { t } = useTranslation();

    const onSubmit = async (data: any) => {
        if (data[NEW_PASSWORD] !== data[NEW_PASSWORD_REPEAT]) {
            toast(t('passwordsDontMatch'), { type: 'error' });

        }
        try {
            await authStore.setPassword(token!, data[EMAIL], data[NEW_PASSWORD]);
            toast(t('saved'), { type: 'success' });
            navigate(routes.pageLogin);
        }
        catch (error) {
            toast(t('tokenInvalidTryResetPasswordAgain'), { type: 'error' });
            navigate(routes.pageForgotPassword);
        }

    }

    return (
        <NotLoggedInWrapper>
            <Form
                validation={(yup) => ({
                    [NEW_PASSWORD]: yup.string().required('*'),
                    [NEW_PASSWORD_REPEAT]: yup.string().required('*')
                })}
                onSubmit={onSubmit}>
                <div className='grid gap-4 '>
                    <Input
                        label={t('email')}
                        name={EMAIL}
                        type='email'
                    />
                    <Input
                        label={t('newPassword')}
                        name={NEW_PASSWORD}
                        type='password'
                    />
                    <Input
                        label={t('newPasswordRepeat')}
                        name={NEW_PASSWORD_REPEAT}
                        type='password'
                    />
                    <div className='text-right'>
                        <Button
                            appearance='primary'
                            type='submit'
                            label={t('resetPassword')}
                        />
                    </div>
                </div>
            </Form>
        </NotLoggedInWrapper>
    )
}

export default PageResetPassword;