import Button from 'components/Forms/Button';
import FormsTable from 'components/FormsTable';
import { routes } from 'components/Routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PageFront = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            <div className='text-right pt-1 pb-3'>
                <Button
                    type='button'
                    appearance='primary'
                    label={t('createForm')}
                    onClick={() => navigate(routes.pageFormCreate)}
                />
            </div>
            <FormsTable />
        </div>
    )
}

export default PageFront;