import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { unstable_usePrompt } from 'react-router-dom';

const BlockDirtyForm = () => {
    const { formState } = useFormContext();
    const { t } = useTranslation();

    const prompt = unstable_usePrompt({ 
        message: t('unsavedChangesContinueAnyway'), 
        when: formState.isDirty
    })

    return <div></div>;
}

export default BlockDirtyForm;