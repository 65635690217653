import { useNavigate } from 'react-router-dom';

interface Props {
    title: string
}

const TitleWithBackArrow = (props: Props) => {
    const navigate = useNavigate();

    return (
        <h1 className='text-2xl flex-1'>
            <div className='flex items-center'>
                <span
                    onClick={() => navigate(-1)}
                    className='pr-4 text-3xl text-blue-800 cursor-pointer'>
                    &larr;
                </span>
                {props.title}
            </div>
        </h1>
    )

}

export default TitleWithBackArrow;