import { CategoryDto } from 'types/CategoryDto';
import { BaseStore } from './BaseStore';
import { makeObservable } from 'mobx';

class CompanyCategoriesStore extends BaseStore {

    categories: Array<CategoryDto> = [];

    constructor() {
        super();

        makeObservable(this, {
            categories: true,

            resetStore: true,
            getCategories: true,
            createCategory: true,
            deleteCategory: true
        })
    }

    resetStore = () => {
        this.categories = [];
    }

    getCategories = (companyId: string) => {
        return this.get(`companies/${companyId}/categories`, 'categories');
    }

    createCategory = (companyId: string, categoryId: string) => {
        return this.post(`companies/${companyId}/categories`, {
            id: categoryId
        }, this.categories, true);
    }

    deleteCategory = (companyId: string, categoryId: string) => {
        return this.delete(`companies/${companyId}/categories/${categoryId}`, categoryId, this.categories);
    }

}

export const companyCategoriesStore = new CompanyCategoriesStore();