import Table from 'components/Table';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formsStore } from 'stores/FormsStore';
import { generatePath, useNavigate } from 'react-router-dom';
import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import { FiCheckCircle } from 'react-icons/fi';
import { FiXCircle } from 'react-icons/fi';
import _ from 'lodash';

const FormsTable = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        formsStore.getSuperAdminForms();

        return () => {
            formsStore.resetStore();
        }
    }, [])


    const forms = _.orderBy(formsStore.forms, f => f.title);

    return (
        <Table
            defaultSortLabel='title'
            data={forms}
            onRowClicked={(item) => navigate(generatePath(superAdminRoutes.pageFormEdit, { id: item.id }))}
            render={[
                {
                    label: t('form'),
                    cell: (item) => item.title,
                    sortProperty: (item) => item.title
                },
                {
                    label: t('demo'),
                    cell: (item) => item.isDemoTemplate 
                        ? <FiCheckCircle className='text-green-600' /> 
                        : <FiXCircle className='text-gray-300' />,
                    sortProperty: (item) => item.isDemoTemplate.toString(),
                    width: 80
                },
                {
                    label: t('status'),
                    cell: (item) => item.isDraft ? t('draft') : t('published'),
                    sortProperty: (item) => item.isDraft ? t('draft') : t('published'),
                    width: 140
                },
                {
                    label: t('modified'),
                    cell: (item) => moment(item.modifiedOn || item.createdOn).format('DD-MM-YY'),
                    sortProperty: (item) => moment(item.modifiedOn || item.createdOn).format('DD-MM-YY'),
                    width: 110
                }
            ]}
        />
    )

})

export default FormsTable;