import UsersTable from 'components/SuperAdmin/UsersTable';

const PageUsers = () => {
    return (
        <div>
            <UsersTable />
        </div>
    )
}

export default PageUsers;