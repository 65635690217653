import { Table as MaterialUITable, TableHead, TableBody, TableContainer, TableRow, TableSortLabel, TableCell } from '@mui/material';
import { useState } from 'react';
import _ from 'lodash';

type RenderType<T> = {
    label: string,
    sortProperty?: (item: T) => string,
    // rowFormat?: (row: any) => void,
    cell: (item: T) => React.ReactNode,
    appendToLabel?: React.ReactNode,
    width?: string | number
}

type Props<T> = {
    data: Array<T>,
    render: Array<RenderType<T>>,
    defaultSortLabel?: string,
    defaultSortDirection?: 'asc' | 'desc',
    onRowClicked?: (item: T) => void,
    rowStyle?: (item: T, index: number) => React.CSSProperties
}

function Table<T>(props: Props<T>) {
    const [orderByLabel, setOrderByLabel] = useState(props.defaultSortLabel);
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc' | undefined>(props.defaultSortDirection || undefined);

    const onSort = (label: string) => {
        if (orderByLabel === label) {
            orderDirection === 'asc' ? setOrderDirection('desc') : setOrderDirection('asc')
        }
        else {
            setOrderByLabel(label);
            setOrderDirection('asc');
        }
    }

    let data = props.data;
    if (orderDirection && orderByLabel) {
        const item = _.find(props.render, r => r.label === orderByLabel);
        if (item) {
            data = _.orderBy(props.data, d => item.sortProperty!(d), orderDirection);
        }
    }

    return (
        <TableContainer className='border rounded min-w-[600px]'>
            <MaterialUITable style={{ tableLayout: 'fixed' }}>
                <TableHead className='text-primary-green'>
                    <TableRow>
                        {
                            props.render.map((r, index) => {
                                return (
                                    <TableCell width={r.width} key={index} style={{ verticalAlign: 'top' }}>
                                        {
                                            r.sortProperty &&
                                            <TableSortLabel
                                                onClick={() => onSort(r.label)}
                                                active={orderByLabel === r.label}
                                                direction={orderDirection}>
                                                {r.label}
                                            </TableSortLabel>
                                        }

                                        {
                                            !r.sortProperty &&
                                            <div>{r.label}</div>
                                        }
                                        {r.appendToLabel}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((item, i) => {

                            return (
                                <TableRow style={{
                                        background: i % 2 === 0 ? '#fafafa' : undefined,
                                        cursor: props.onRowClicked ? 'pointer' : 'initial'
                                    }} 
                                    key={i} 
                                    onClick={() => props.onRowClicked ? props.onRowClicked(item) : undefined}>
                                    {props.render.map((r, index) => {
                                        return <TableCell style={{ border: 0 }} key={index}>{r.cell(item)}</TableCell>
                                    })}
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </MaterialUITable>
        </TableContainer>
    )
}

export default Table;