import { PropsWithChildren } from 'react';
import LogoImg from './logo.svg';

const NotLoggedInWrapper = (props: PropsWithChildren) => {

    return (
        <div className='
            mx-auto 
            w-full 
            p-12 
            lg:p-0 
            lg:w-96 
            mt-32
            '>
            <div>
                <img className='mx-auto rounded-lg' src={LogoImg} alt="Safety Observer app" width={80} />
                <h1 className='text-center text-2xl mb-12 pt-4'>
                    Safety Observer app 3.0
                    <span className='text-sm block text-gray-500 pt-2'>Administration</span>
                </h1>
            </div>

            {props.children}
        </div>
    )

}

export default NotLoggedInWrapper;