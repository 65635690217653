import { action, makeObservable, observable } from 'mobx';
import { CompanyInterface } from 'types/CompanyInterface';
import { CompanyUserInterface } from 'types/CompanyUserInterface';
import { BaseStore } from './BaseStore';

class SelectedCompanyStore extends BaseStore {

    userCompanies: Array<CompanyUserInterface> = [];
    selectedCompany: CompanyInterface | null = null;
    loggedInAsSuperAdmin: boolean = false;

    constructor() {
        super('SelectedCompanyStore');

        makeObservable(this, {
            userCompanies: observable,
            selectedCompany: observable,
            loggedInAsSuperAdmin: observable,

            resetStore: action,
            setSelectedCompany: action,
            setUserCompanies: action,
            setLoggedInAsSuperAdmin: action
        })

        this.initSessionStorage(this, ['userCompanies', 'selectedCompany', 'loggedInAsSuperAdmin']);
    }

    resetStore = () => {
        this.userCompanies = [];
        this.selectedCompany = null;
        this.loggedInAsSuperAdmin = false;
    }

    setSelectedCompany = (company: CompanyInterface | null) => {
        this.selectedCompany = company;
    }

    setLoggedInAsSuperAdmin = (value: boolean) => {
        this.loggedInAsSuperAdmin = value;
    }

    setUserCompanies = (companies: Array<CompanyUserInterface>) => {
        this.userCompanies = companies;
    }

}

export const selectedCompanyStore = new SelectedCompanyStore();