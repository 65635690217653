import Label from "components/Forms/Label";
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

import './style.module.scss';
import { FormElementInterface } from 'types/FormElementInterface';

export interface OptionInterface {
    id: string,
    value: string
}

interface Props extends FormElementInterface {
    options: Array<OptionInterface>,
    disabled?: boolean,
    className?: string
}

const Select = (props: Props) => {
    const { register, unregister } = useFormContext();

    // useEffect(() => {
    //     return () => {
    //         unregister(props.name)
    //     }
    // }, []);

    return (
        <div className={`w-full ${props.disabled ? 'cursor-not-allowed' : ''} ${props.className || ''}`}>
            <Label name={props.name} label={props.label} />
            <select
                className={props.className}
                {...register(props.name)}>
                {
                    props.placeholder &&
                    <option value=''>{props.placeholder}</option>
                }

                {
                    props.options.map((opt, index) => (
                        <option key={index} value={opt.id} selected={props.defaultValue === opt.id}>
                            {opt.value}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}

export default Select;