import ArrowButton, { ArrowButtonDirection } from 'components/ArrowButton';
import InputHidden from 'components/Forms/InputHidden';
import TrashButton from 'components/TrashButton';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SchemaFormEnum } from 'types/SchemaFormEnum';

interface Props {
    formKey: string,
    index: number,
    formSectionIndex: number,
    onMoveUp?: () => void,
    onMoveDown?: () => void,
    onDelete?: () => void
}

const FormSectionItem = (props: Props) => {
    const { register, unregister, formState } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(`${props.formKey}.${SchemaFormEnum.title}`)
        }
    }, []);
    
    const name = `${props.formKey}.${SchemaFormEnum.title}`;
    const errorMsg = get(formState.errors, (`${name}.message`)) as any;

    return (
        <div className='my-4'>
            <div className='flex items-center'>
                <div className='w-[70px]'>
                    <h2 className='text-xl pt-1'>{props.formSectionIndex + 1}.{props.index + 1}</h2>
                </div>
                <div className='flex-1'>
                    <div className='flex'>
                        <div className='flex-1'>
                            <InputHidden name={SchemaFormEnum.id} />
                            <input
                                className={`border p-2 w-full rounded-md ${errorMsg ? 'border-red-800' : ''}`}
                                {...register(name)}
                                type='text'
                                placeholder='Overskrift'
                            />
                        </div>
                        <div className='flex pt-1'>
                            <ArrowButton
                                className='ml-2'
                                direction={ArrowButtonDirection.UP}
                                onClick={props.onMoveUp}
                                disabled={props.onMoveUp === undefined}
                            />
                            <ArrowButton
                                className='mx-2'
                                direction={ArrowButtonDirection.DOWN}
                                onClick={props.onMoveDown}
                                disabled={props.onMoveDown === undefined}
                            />
                            <TrashButton
                                disabled={props.onDelete === undefined}
                                onClick={props.onDelete}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default FormSectionItem;