import SidebarMenu from 'components/SidebarMenu';
import { routes } from 'components/Routes';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { authStore } from 'stores/AuthStore';
import Header from 'components/Header';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import MenuItem from 'components/SidebarMenu/MenuItem';
import { useTranslation } from 'react-i18next';

const LoggedInWrapper = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const isLoggedIn = authStore.isLoggedIn;
    const { selectedCompany } = selectedCompanyStore;
    useEffect(() => {
        if (!isLoggedIn) {
            navigate(routes.pageLogin);
        }
        else if (!selectedCompany) {
            navigate(routes.pageSelectUserCompany);
        }
    }, [isLoggedIn, navigate, selectedCompany])

    if (!isLoggedIn) return null;

    return (
        <div className='mx-auto max-w-screen-xl p-8 min-w-[800px]'>
            <Header />
            <div className='flex'>
                <div className=''>
                    <SidebarMenu>
                        <MenuItem
                            label={t('forms')}
                            onClick={() => navigate(routes.pageFront)}
                            isActive={pathname === routes.pageFront}
                        />
                        <MenuItem
                            label={t('users')}
                            onClick={() => navigate(routes.pageUsers)}
                            isActive={pathname === routes.pageUsers}
                        />
                        <MenuItem
                            label={t('company')}
                            onClick={() => navigate(routes.pageCompany)}
                            isActive={pathname === routes.pageCompany}
                        />
                        <MenuItem
                            label={t('myProfile')}
                            onClick={() => navigate(routes.pageMyProfile)}
                            isActive={pathname === routes.pageMyProfile}
                        />
                    </SidebarMenu>
                </div>
                <div className='flex-1 bg-white ml-6 rounded p-4 shadow'>
                    <Outlet />
                </div>
            </div>


        </div>
    )

}

export default LoggedInWrapper;