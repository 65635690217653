import { Controller, useFormContext } from "react-hook-form";
import React, { createRef, useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import css from './style.module.scss'; // Module specific
import './style.scss';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

interface Props {
    name: string
    className?: string
}

const HtmlEditor = (props: Props) => {
    const editorRef = createRef<Editor>();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    // const createEditorStateFromHtmlString = (inputStr: string) => {
    //     const state = stateFromHTML(inputStr);
    //     // const editorState = EditorState.createWithContent(state)
    //     const st = EditorState.set(editorState, { currentContent: ContentState.createFromText(inputStr) })
    //     // setEditorState(st);
    //     // return editorState;
    // }

    const getEditorOptions = () => {
        return {
            options: ['inline', 'list', 'link', 'image'],
            inline: {
                options: ['bold', 'italic', 'underline'],
            },
            list: {
                options: ['unordered']
            },
            link: {
                options: ['link', 'unlink']
            },
            image: {
                alignmentEnabled: false
            }
        }
    }

    const ctx = useFormContext();
    const incomingValue = ctx.getValues(props.name);
    const currentValue = stateToHTML(editorState.getCurrentContent());

    useEffect(() => {
        if (incomingValue !== undefined && incomingValue !== currentValue) {
            const state = stateFromHTML(incomingValue);
            const eState = EditorState.createWithContent(state)
            setEditorState(eState)
        }
    }, [incomingValue, currentValue])

    useEffect(() => {
        return () => {
            ctx.unregister(props.name)
        }
    }, []);


    return (
        <div className={props.className}>
            <div className={css.wrapper}>
                <Controller
                    name={props.name}
                    control={ctx.control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => {
                        const onValueChanged = (state: EditorState) => {
                            const rawHtml = stateToHTML(state.getCurrentContent());
                            onChange(rawHtml);
                            setEditorState(EditorState.set(state, { currentContent: state.getCurrentContent() }))
                        }

                        return (
                            <Editor
                                ref={editorRef}
                                editorStyle={{ height: '200px' }}
                                toolbar={getEditorOptions()}
                                onEditorStateChange={onValueChanged}
                                // defaultEditorState={EditorState.createEmpty()}
                                editorState={editorState}
                                // defaultEditorState={value ? createEditorStateFromHtmlString(value) : EditorState.createEmpty()}
                            />
                        )
                    }} />
            </div>
        </div>

    )
}

export default HtmlEditor;