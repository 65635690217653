import { action, makeObservable, observable } from 'mobx';
import { FormDetailsInterface } from 'types/FormDetailsInterface';
import { SafetyIndexInterface } from 'types/SafetyIndexInterface';
import { SaveFormInterface } from 'types/SaveFormInterface';
import { BaseStore } from './BaseStore';

class FormDetailsStore extends BaseStore {

    form: FormDetailsInterface | null = null;
    safetyIndexes: Array<SafetyIndexInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            form: observable,
            safetyIndexes: observable,

            resetStore: action,
            resetSafetyIndexes: action,
            getForm: action,
            updateForm: action,
            createForm: action,
            deleteForm: action,
            getSafetyIndexes: action
        })
    }

    resetStore = () => {
        this.form = null;
        this.resetSafetyIndexes();
    }

    resetSafetyIndexes = () => {
        this.safetyIndexes = [];
    }

    getForm = (formId: string) => {
        return this.get(`forms/${formId}/full`, 'form');
    }

    updateForm = (form: SaveFormInterface) => {
        return this.put(`forms/${form.id!}`, form, this.form, true);
    }

    createForm = (form: SaveFormInterface) => {
        return this.post(`forms`, form, this.form, true);
    }

    deleteForm = (formId: string) => {
        return this.delete(`forms/${formId}`, formId, this.form);
    }

    getSafetyIndexes = (formId: string) => {
        return this.get(`forms/${formId}/submissions/safety-index`, 'safetyIndexes');
    }

    shareForm = (formId: string, companyCode: string) => {
        return this.post(`forms/${formId}/copy`, {
            companyCode: companyCode
        }, null, true);
    }

}

export const formDetailsStore = new FormDetailsStore();