import { FormListInterface } from 'types/FormListInterface';
import { FiEdit } from 'react-icons/fi';
import { FiFile } from 'react-icons/fi';
import { FcDataSheet } from 'react-icons/fc';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'components/Routes';
import { formSubmissionStore } from 'stores/FormSubmissionStore';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { authStore } from 'stores/AuthStore';

interface Props {
    item: FormListInterface
}

const Actions = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onEdit = (item: FormListInterface) => {
        navigate(generatePath(routes.pageFormEdit, { id: item.id }));
    }

    const onGotoFormSubmissions = (item: FormListInterface) => {
        navigate(generatePath(routes.pageFormSubmissions, { id: item.id }));
    }

    const onDownloadCsv = async (item: FormListInterface) => {
        const downloadToken = await authStore.getDownloadToken() as string;
        formSubmissionStore.exportSubmissions(item.id, downloadToken);
    }

    const item = props.item;
    const iconClass = 'p-2 rounded bg-gray-100 text-gray-700 inline-block mx-1';

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title={t('edit')}>
                <span>
                    <FiEdit
                        className={iconClass}
                        size={32}
                        strokeWidth={1}
                        onClick={() => onEdit(item)}
                    />
                </span>
            </Tooltip>

            <Tooltip title={t('pdfReports')}>
                <span>
                    <FiFile
                        className={iconClass}
                        size={32}
                        strokeWidth={1}
                        onClick={() => onGotoFormSubmissions(item)}
                    />
                </span>
            </Tooltip>

            <Tooltip title={t('downloadCsv')}>
                <span>
                    <FcDataSheet
                        className={iconClass}
                        size={32}
                        strokeWidth={1}
                        onClick={() => onDownloadCsv(item)}
                    />
                </span>
            </Tooltip>
        </div>
    )

}

export default Actions;