interface Props {
    imagePath: string,
    alt: string,
    isActive: boolean,
    onClick: () => void
}

const LanguageIcon = (props: Props) => {

    let className = 'cursor-pointer mx-1';

    if (!props.isActive) {
        className += ' opacity-30';
    }

    return (
        <img
            src={props.imagePath}
            alt={props.alt}
            className={className}
            onClick={props.onClick}
        />
    )
}

export default LanguageIcon;