import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import { getErrorMessage } from 'helpers/ErrorHelper';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { userDetailsStore } from 'stores/UserDetailsStore';
import { UserInterface } from 'types/UserInterface';
import DeleteMe from './DeleteMe';

const NAME = 'name';
const EMAIL = 'email';

interface OnSubmitInterface {
    [NAME]: string,
    [EMAIL]: string
}

const PageMyProfile = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        userDetailsStore.getMe();

        return () => {
            userDetailsStore.resetStore();
        }
    }, [])

    const onSubmit = async (user: UserInterface, data: OnSubmitInterface) => {
        user.name = data[NAME];
        user.email = data[EMAIL];

        try {
            await userDetailsStore.updateMe(user);
            toast(t('saved'), { type: 'success' });
        }
        catch (error: any) {
            toast(getErrorMessage(error), { type: 'error' });
        }
    }

    const user = userDetailsStore.user;
    if (!user) return null;

    return (
        <div>
            <div className='w-96'>
                <Form
                    onSubmit={(data) => onSubmit(user, data)}
                    validation={(yup) => ({
                        [NAME]: yup.string().required('*'),
                        [EMAIL]: yup.string().required('*')
                    })}
                    formOptions={{
                        defaultValues: {
                            [NAME]: user.name,
                            [EMAIL]: user.email
                        }
                    }}>

                    <Input
                        label={t('name')}
                        name={NAME}
                    />

                    <Input
                        label={t('email')}
                        name={EMAIL}
                        className='mt-6'
                    />

                    <div className='mt-12'>
                        <Button
                            appearance='primary'
                            label={t('save')}
                            type='submit'
                        />
                    </div>
                </Form>
            </div>
            <DeleteMe />
        </div>
    )
})

export default PageMyProfile;