import HideLanguageToggle from 'components/HideLanguageToggle';
import PageFormEdit from 'components/Pages/PageFormEdit';
import PageCategories from 'components/Pages/SuperAdmin/PageCategories';
import PageCompanies from 'components/Pages/SuperAdmin/PageCompanies';
import PageCompanyDetails from 'components/Pages/SuperAdmin/PageCompanyDetails';
import PageFormCreate from 'components/Pages/SuperAdmin/PageFormCreate';
import PageFront from 'components/Pages/SuperAdmin/PageFront';
import PageInformation from 'components/Pages/SuperAdmin/PageInformation';
import PageUserDetails from 'components/Pages/SuperAdmin/PageUserDetails';
import PageUsers from 'components/Pages/SuperAdmin/PageUsers';
import LoggedInWrapper from 'components/SuperAdmin/LoggedInWrapper';
import { Route, Routes } from 'react-router-dom';

export const superAdminRoutes = {
    pageFront: '/superadmin',
    pageFormEdit: '/superadmin/forms/:id',
    pageFormCreate: '/superadmin/forms/create',
    pageUsers: '/superadmin/users',
    pageUserDetails: '/superadmin/users/:id',
    pageCategories: '/superadmin/categories',
    pageCompanies: '/superadmin/companies',
    pageCompanyDetails: '/superadmin/companies/:id',
    pageInformation: '/superadmin/information',
    pageInformationDetails: '/superadmin/information/:id',
}


const SuperAdminRoutes = () => {

    return (
        <Routes>
            <Route element={<LoggedInWrapper />}>
                <Route path={superAdminRoutes.pageFront} element={<PageFront />} />
                <Route path={superAdminRoutes.pageUsers} element={<PageUsers />} />
                <Route path={superAdminRoutes.pageUserDetails} element={<PageUserDetails />} />
                <Route path={superAdminRoutes.pageCategories} element={<PageCategories />} />
                <Route path={superAdminRoutes.pageCompanies} element={<PageCompanies />} />
                <Route path={superAdminRoutes.pageCompanyDetails} element={<PageCompanyDetails />} />
                <Route path={superAdminRoutes.pageInformation} element={<PageInformation />} />

                <Route element={<HideLanguageToggle />}>
                    <Route path={superAdminRoutes.pageFormCreate} element={<PageFormCreate />} />
                    <Route path={superAdminRoutes.pageFormEdit} element={<PageFormEdit canSaveCategory />} />
                </Route>
            </Route>
        </Routes>
    )

}

export default SuperAdminRoutes;