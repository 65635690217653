import React, { Ref } from "react";
import { FormProvider, useForm, UseFormProps } from "react-hook-form";
import { ObjectShape } from "yup/lib/object";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { YupInterface } from 'types/YupInterface';
import { toast } from 'react-toastify';
import { t } from 'i18next';

interface Props {
    formOptions?: UseFormProps,
    children: React.ReactNode,
    onSubmit: (data: any) => void,
    validation?: (yupInstance: YupInterface) => ObjectShape,
    ref?: Ref<any>,
    resetOnSubmit?: boolean
}

const Form = (props: Props) => {

    let formOptions = props.formOptions || {};
    if (props.validation) {
        formOptions.resolver = yupResolver(yup.object().shape(props.validation(yup)));
    }

    const methods = useForm(formOptions);
    const { handleSubmit } = methods;

    const stopPropagate = (callback: () => void) => {
        return (e: any) => {
            e.preventDefault();
            e.stopPropagation();

            callback()

            if (props.resetOnSubmit) {
                methods.reset()
            }

            methods.reset({}, { keepValues: true });
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={stopPropagate(handleSubmit(props.onSubmit, () => {
                toast(t('oneOrMoreFieldsNotFilledOut'), { type: 'error' });
            }))} className='w-full' ref={props.ref}>
                {props.children}
            </form>
        </FormProvider>
    );
}

export default Form;