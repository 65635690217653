import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { companyDetailsStore } from 'stores/CompanyDetailsStore';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import { CompanyInterface } from 'types/CompanyInterface';
import DeleteCompany from './DeleteCompany';
import { getErrorMessage } from 'helpers/ErrorHelper';
import { authStore } from 'stores/AuthStore';
import CompanyCategories from './CompanyCategories';

const PageCompany = observer(() => {
    const { t } = useTranslation();

    const updateName = (company: CompanyInterface) => {
        const newCompanyName = window.prompt(t('companyName')!, company.name);
        if (!newCompanyName || newCompanyName === company.name) return;

        company.name = newCompanyName;

        companyDetailsStore.updateCompany(company);
        selectedCompanyStore.setSelectedCompany(company);
        toast(t('saved'), { type: 'success' });
    }

    const createNewCompany = async () => {
        const companyName = window.prompt(t('companyName')!);
        if (!companyName) return;

        try {
            await authStore.createCompany(companyName);
        }
        catch (error) {
            toast(getErrorMessage(error), { type: 'error' });
        }
    }

    const company = selectedCompanyStore.selectedCompany;
    if (!company) return null;

    return (
        <div>
            <h1 className='text-2xl'>
                {t('company')}
            </h1>
            <div className='px-8 pb-12'>
                <p className='my-8 max-w-[600px]'>
                    {t('companyCodeDescription')}.
                </p>

                <dl className='w-full overflow-hidden'>
                    <dt className='float-left w-[180px] font-semibold'>{t('companyName')}:</dt>
                    <dd className='mb-4'>
                        {company.name}
                        <button
                            className='ml-2 text-xs underline text-gray-600'
                            type='button'
                            onClick={() => updateName(company)}>
                            {t('edit')}
                        </button>
                    </dd>

                    <dt className='float-left w-[180px] font-semibold'>{t('companyCode')}:</dt>
                    <dd>{company.companyCode}</dd>
                </dl>
            </div>
            <div className='flex mt-12'>
                <div className='flex-1'>
                    <button
                        onClick={createNewCompany}
                        className='underline text-blue-800'
                        type='button'>
                        {t('createNewCompany')}
                    </button>
                </div>
                <div>
                    <DeleteCompany companyId={company.id} />
                </div>
            </div>
            <CompanyCategories company={company} />
        </div>
    )
})

export default PageCompany;