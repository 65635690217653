import { routes } from 'components/Routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BackToFrontpageButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='mt-12'>
            <div className='text-center border-t'>
                <button
                    type='button'
                    onClick={() => navigate(routes.pageLogin)}
                    className='text-gray-600 mt-6 underline'>
                    {t('backToFrontpage')}
                </button>
            </div>
        </div>
    )
}

export default BackToFrontpageButton;