import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import TitleWithBackArrow from 'components/TitleWithBackArrow';
import { getErrorMessage } from 'helpers/ErrorHelper';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { companyDetailsStore } from 'stores/CompanyDetailsStore';
import { CompanyInterface } from 'types/CompanyInterface';
import UsersTable from './UsersTable';

const COMPANY_NAME = 'name';
const COMPANY_CODE = 'companyCode';

interface OnSubmitInterface {
    [COMPANY_NAME]: string,
    [COMPANY_CODE]: string
}

const PageCompanyDetails = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            companyDetailsStore.getCompany(id);
        }

        return () => {
            companyDetailsStore.resetStore();
        }

    }, [id])

    const onDelete = async (companyId: string) => {
        if (!window.confirm(t('confirmCompanyDelete')!)) return;

        try {
            await companyDetailsStore.deleteCompany(companyId);
            toast(t('companyDeleted'), { type: 'success' });
            navigate(superAdminRoutes.pageCompanies);
        }
        catch (error) {
            toast(getErrorMessage(error), { type: 'error' });
        }
    }

    const onSubmit = async (company: CompanyInterface, data: OnSubmitInterface) => {
        company.name = data[COMPANY_NAME];
        company.companyCode = data[COMPANY_CODE];

        try {
            await companyDetailsStore.updateCompany(company);
            toast(t('saved'), { type: 'success' });
            navigate(superAdminRoutes.pageCompanies);
        }
        catch (error) {
            toast(getErrorMessage(error), { type: 'error' });
        }
    }

    const company = companyDetailsStore.company;
    if (!company) return null;

    return (
        <div>
            <div className='mb-8'>
                <TitleWithBackArrow title={t('company')} />
            </div>

            <div className='mx-12'>
                <div className='w-96'>
                    <Form
                        validation={(yup) => ({
                            [COMPANY_NAME]: yup.string().required('*'),
                            [COMPANY_CODE]: yup.string().required('*'),
                        })}
                        formOptions={{
                            defaultValues: {
                                [COMPANY_NAME]: company.name,
                                [COMPANY_CODE]: company.companyCode
                            }
                        }}
                        onSubmit={(data) => onSubmit(company, data)}>
                        <Input
                            label={t('companyName')}
                            name={COMPANY_NAME}
                        />
                        <Input
                            label={t('companyCode')}
                            name={COMPANY_CODE}
                            className='mt-6'
                        />
                        <div className='mt-12'>
                            <Button
                                appearance='primary'
                                label={t('save')}
                                type='submit'
                            />
                        </div>
                    </Form>
                </div>

                <div className='mt-16'>
                    <h1 className='mb-10 font-semibold'>{t('users')}</h1>
                    <UsersTable companyId={company.id} />
                </div>

                <div className='mt-12 mb-6 text-right'>
                    <button
                        onClick={() => onDelete(company.id)}
                        className='underline text-gray-400'
                        type='button'>
                        {t('delete')} {t('company').toLowerCase()}
                    </button>
                </div>

            </div>

        </div>

    )
})

export default PageCompanyDetails;