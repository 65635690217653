import SchemaForm from 'components/SchemaForm';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formDetailsStore } from 'stores/FormDetailsStore';
import { SaveFormInterface } from 'types/SaveFormInterface';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'components/Routes';

const PageFormCreate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = async (data: SaveFormInterface) => {
        try {

            data.company = {
                id: selectedCompanyStore.selectedCompany!.id
            }

            await formDetailsStore.createForm(data);
            toast(t('saved'), { type: 'success' });

            navigate(generatePath(routes.pageFront))
            
        }
        catch (error) {
            toast(error as any, { type: 'error' });
        }
    }

    return (
        <SchemaForm
            title={t('createForm')}
            onSubmit={onSubmit}
            defaultValues={{
                area: false,
                areaRequired: false,
                section: false,
                sectionRequired: false,
                preliminaryNoteField: false,
                smileys: false,
                notes: false,
                photos: false,
                photosGPS: false,
                isDraft: false,
                emails: '',
                title: '',
                sections: [
                    {
                        title: '',
                        description: '',
                        items: [{
                            title: ''
                        }]
                    }
                ]
            }}
        />
    )
}

export default PageFormCreate;