import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import Table from 'components/Table';
import { companyRoleToString } from 'helpers/RoleHelper';
import { find, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { CompanyUserInterface } from 'types/CompanyUserInterface';

interface Props {
    companies: Array<CompanyUserInterface>
}

const UserCompaniesTable = (props: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Table
            onRowClicked={(item) => navigate(generatePath(superAdminRoutes.pageCompanyDetails, { id: item.company.id }))}
            data={orderBy(props.companies, c => c.company.name)}
            render={[
                {
                    label: t('company'),
                    cell: (item) => item.company.name
                },
                {
                    label: t('role'),
                    cell: (item) => companyRoleToString(item.role),
                    sortProperty: (item) => companyRoleToString(item.role),
                }
            ]}
        />
    )
}

export default UserCompaniesTable;