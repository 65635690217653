import { action, makeObservable, observable } from 'mobx';
import { InformationInterface } from 'types/InformationInterface';
import { LanguageEnum } from 'types/LanguageEnum';
import { BaseStore } from './BaseStore';

class InformationStore extends BaseStore {

    information: InformationInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            information: observable,

            resetStore: action,
            getInformation: action,
            updateInformation: action
        })
    }

    resetStore = () => {
        this.information = null;
    }

    getInformation = (language: LanguageEnum) => {
        return this.get(`information?bylanguage=${language}`, 'information');
    }

    updateInformation = (information: InformationInterface) => {
        return this.put(`information/${information.id}`, information, this.information);
    }

}

export const informationStore = new InformationStore();