import FormSectionItem from 'components/FormSectionItem';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SchemaFormEnum } from 'types/SchemaFormEnum';

interface Props {
    formSectionIndex: number
}

const FormSectionItems = (props: Props) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const { fields, append, move, remove } = useFieldArray({
        control: control,

        name: `${SchemaFormEnum.sections}.${props.formSectionIndex}.${SchemaFormEnum.items}`
    });

    const addItem = () => {
        append({
            title: ''
        })
    }

    const deleteItem = (index: number) => {
        remove(index);
    }

    const moveUp = (index: number) => {
        move(index, index - 1);
    }

    const moveDown = (index: number) => {
        move(index, index + 1);
    }

    return (
        <div>
            {
                fields.map((item, index) => {
                    return (
                        <FormSectionItem
                            key={`${SchemaFormEnum.sections}.${props.formSectionIndex}.${SchemaFormEnum.items}.${index}`}
                            formKey={`${SchemaFormEnum.sections}.${props.formSectionIndex}.${SchemaFormEnum.items}.${index}`}
                            index={index}
                            formSectionIndex={props.formSectionIndex}
                            onDelete={fields.length > 1 ? () => deleteItem(index) : undefined}
                            onMoveDown={fields.length > 1 && index < fields.length - 1 ? () => moveDown(index) : undefined}
                            onMoveUp={fields.length > 1 && index > 0 ? () => moveUp(index) : undefined}
                        />
                    )
                })
            }
            <div className='text-left' style={{ marginLeft: '70px' }}>
                <button 
                    type='button' 
                    onClick={() => addItem()}
                    className='bg-gray-100 py-2 px-4 rounded text-sm'>
                    {t('addItem')}
                </button>
            </div>
        </div>
    )
}

export default FormSectionItems;