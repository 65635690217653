import { BaseStore } from './BaseStore';
import { CompanyInterface } from 'types/CompanyInterface';
import { action, makeObservable, observable } from 'mobx';
import { PagingResultInterface } from 'types/PagingResultInterface';
import { buildQuery } from 'helpers/QueryHelper';

class CompanyStore extends BaseStore {

    companies: PagingResultInterface<CompanyInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            companies: observable,

            resetStore: action,
            getCompanies: action
        })
    }

    resetStore = () => {
        this.companies = null;
    }

    getCompanies = (opts?: {
        byName?: string,
        byCode?: string
    }) => {
        let params = {} as any;

        if (opts?.byName) {
            params['byName'] = opts.byName;
        }

        if (opts?.byCode) {
            params['byCode'] = opts.byCode;
        }

        return this.getPaged(buildQuery('companies', opts), this.companies, 'companies');
    }

}

export const companyStore = new CompanyStore();