interface Props {
    label: string,
    value: string
}

const DetailItem = (props: Props) => (
    <>
        <dt className='float-left w-[180px] font-semibold'>{props.label}:</dt>
        <dd className='mb-2'>{props.value}</dd>
    </>
)

export default DetailItem;