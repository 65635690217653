import { action, makeObservable, observable } from 'mobx';
import { UserInterface } from 'types/UserInterface';
import { BaseStore } from './BaseStore';

class UserDetailsStore extends BaseStore {

    user: UserInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            user: observable,

            resetStore: action,
            getUser: action,
            getMe: action,
            updateMe: action,
            deleteMe: action
        })
    }

    resetStore = () => {
        this.user = null;
    }

    getUser = (id: string) => {
        return this.get(`users/${id}`, 'user');
    }

    getMe = () => {
        return this.get(`users/me`, 'user');
    }

    updateMe = (user: UserInterface) => {
        return this.put(`users/me`, user, this.user);
    }

    deleteMe = () => {
        return this.delete(`users/me`, 'me', this.user);
    }

}

export const userDetailsStore = new UserDetailsStore();