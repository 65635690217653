import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import InputHidden from 'components/Forms/InputHidden';
import FormSections from 'components/FormSections';
import FormSettings from 'components/FormSettings';
import { useTranslation } from 'react-i18next';
import { SaveFormInterface } from 'types/SaveFormInterface';
import { SchemaFormEnum } from 'types/SchemaFormEnum';
import { SchemaFormInterface } from '../../types/SchemaFormInterface';
import { languageStore } from 'stores/LanguageStore';
import { FormSettingEnum } from 'types/FormSettingEnum';
import SelectIsDraft from 'components/SelectIsDraft';
import { useNavigate } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { object } from 'yup';
import LoadTemplate from 'components/LoadTemplate';
import BlockDirtyForm from './BlockDirtyForm';
import SelectCategory from 'components/SelectCategory';


interface Props {
    onSubmit: (data: SaveFormInterface) => void,
    defaultValues?: SchemaFormInterface,
    title: string,
    canSaveCategory?: boolean
}

const SchemaForm = (props: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onSubmit = (data: SchemaFormInterface) => {

        // Calculate positions for sections and items, 
        // and add them to form data
        data.sections = data.sections.map((section, sectionIndex) => ({
            ...section,
            position: sectionIndex,
            items: section.items.map((item, itemIndex) => ({
                ...item,
                position: itemIndex
            }))
        }));

        const saveForm: SaveFormInterface = {
            title: data.title,
            emailRecipients: data.emails.split(','),
            id: data.id,
            isDraft: data.isDraft === 'true' ? true : false,
            isTemplate: false,
            isDemoTemplate: data.isDemoTemplate, // Super admins
            language: languageStore.language,
            settingsPreliminaryNoteField: data.preliminaryNoteField ? FormSettingEnum.ON : FormSettingEnum.OFF,
            settingsSectionSmileys: data.smileys ? FormSettingEnum.ON : FormSettingEnum.OFF,
            settingsSectionNotes: data.notes ? FormSettingEnum.ON : FormSettingEnum.OFF,
            settingsSectionPhotos: data.photos ? FormSettingEnum.ON : FormSettingEnum.OFF,
            settingsShowGPSCoordinates: data.photosGPS,
            settingsAreaField:
                data.area && data.areaRequired ? FormSettingEnum.REQUIRED
                    : data.area ? FormSettingEnum.ON : FormSettingEnum.OFF,
            settingsSectionField:
                data.section && data.sectionRequired ? FormSettingEnum.REQUIRED
                    : data.section ? FormSettingEnum.ON : FormSettingEnum.OFF,
            sections: data.sections as any
        }

        if (props.canSaveCategory) {
            saveForm.category = data.category ? {
                id: data.category
            } : null
        }

        props.onSubmit(saveForm);
    }

    return (
        <Form
            // resetOnSubmit={true} // When resetting on submit, dirty fields are reset and we can safely navigate away
            onSubmit={onSubmit}
            validation={(yup) => ({
                [SchemaFormEnum.title]: yup.string().required('*'),
                [SchemaFormEnum.sections]: yup.array().of(object().shape({
                    [SchemaFormEnum.title]: yup.string().required('*'),
                    [SchemaFormEnum.items]: yup.array().of(object().shape({
                        [SchemaFormEnum.title]: yup.string().required('*')
                    }))
                }))
            })}
            formOptions={{ defaultValues: props.defaultValues }}>
            <BlockDirtyForm />
            <InputHidden name={SchemaFormEnum.id} />
            <div className='mb-4'>
                <div className='flex'>
                    <div
                        onClick={() => navigate(-1)}
                        className='pl-6 text-3xl text-blue-800 cursor-pointer' style={{ width: '102px', marginTop: '13px' }}>
                        &larr;
                    </div>
                    <div className='flex-1'>
                        <Sticky innerClass='z-50'>
                            <div className='flex py-3 items-center bg-white'>
                                <h1 className='text-2xl flex-1 bg-white'>
                                    {props.title}
                                </h1>

                                <div className='text-right flex items-center'>
                                    <SelectIsDraft />
                                    <Button
                                        appearance='primary'
                                        type='submit'
                                        label={t('saveForm')}
                                    />
                                </div>
                            </div>
                        </Sticky>
                        <FormSettings />
                    </div>


                </div>

                <div className='mt-16 px-8 pr-0' style={{ paddingLeft: '102px' }}>
                    <Input
                        label={t('nameOfForm')}
                        name={SchemaFormEnum.title}
                    />

                    <div className='bg-gray-50 px-4 pt-2 pb-4 mt-2 rounded border'>
                    {
                        props.canSaveCategory && <div className='pt-2 inline-block'><SelectCategory name={SchemaFormEnum.category} /></div>
                    }
                    
                    {
                        !props.canSaveCategory && (
                            <div className={`flex items-center ${props.canSaveCategory ? 'justify-between' : 'justify-end'}`}>
                                <LoadTemplate />
                            </div>
                        )
                    }
                    </div>


                    
                </div>

                <FormSections />

            </div >

        </Form>
    )
}

export default SchemaForm;