import { CategoryDto } from 'types/CategoryDto';
import { BaseStore } from './BaseStore';
import { action, makeObservable, observable } from 'mobx';

class SuperadminCategoriesStore extends BaseStore {

    categories: Array<CategoryDto> = [];

    constructor() {
        super();

        makeObservable(this, {
            categories: observable,

            resetStore: action,
            getCategories: action,
            createCategory: action,
            deleteCategory: action,
            updateCategory: action
        })
    }

    resetStore = () => {
        this.categories = [];
    }

    getCategories = () => {
        return this.get('categories', 'categories');
    }

    createCategory = async (category: CategoryDto) => {
        const newCategory = await this.post('categories', category, null, true);
        this.categories = [newCategory, ...this.categories]
    }

    deleteCategory = async (categoryId: string) => {
        await this.delete(`categories/${categoryId}`, categoryId, this.categories);
        this.categories = this.categories.filter(c => c.id !== categoryId);
    }

    updateCategory = async (category: CategoryDto) => {
        const updatedCategory = await this.put(`categories/${category.id}`, category, null, true) as CategoryDto;
        this.categories = this.categories.map(c => c.id === category.id ? updatedCategory : c);
    }

}

export const superadminCategoriesStore = new SuperadminCategoriesStore();