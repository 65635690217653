import Table from 'components/Table';
import TrashButton from 'components/TrashButton';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { superadminCategoriesStore } from 'stores/SuperadminCategoriesStore';
import { CategoryDto } from 'types/CategoryDto';
import { FiEdit2 } from 'react-icons/fi';

const CategoriesTable = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        superadminCategoriesStore.getCategories();

        return () => {
            superadminCategoriesStore.resetStore();
        }
    }, [])

    const onDelete = async (id: string) => {
        if (!window.confirm(t('confirmDelete') || '')) return;
        await superadminCategoriesStore.deleteCategory(id);
        toast(t('deleted'), { type: 'success' });
    }

    const onEdit = async (category: CategoryDto) => {
        const newName = window.prompt(t('name')!, category.name);
        if (!newName) return;

        await superadminCategoriesStore.updateCategory({ id: category.id, name: newName });
        toast(t('saved'), { type: 'success' });
    }

    const categories = _.orderBy(superadminCategoriesStore.categories, c => c.name?.toLowerCase());

    return (
        <Table
            defaultSortLabel='name'
            data={categories}
            render={[
                {
                    label: t('name'),
                    cell: (item) => item.name,
                },
                {
                    label: '',
                    cell: (item) => <div className='flex justify-end'>
                            <div className='flex items-center gap-2'>
                                <FiEdit2
                                    className="p-2 rounded text-gray-400 bg-gray-200 cursor-pointer"
                                    size={32}
                                    strokeWidth={1}
                                    onClick={() => onEdit(item)}
                                />
                                <TrashButton onClick={() => onDelete(item.id!)} />
                            </div>
                        </div>
                }
            ]}
        />
    )
})

export default CategoriesTable;