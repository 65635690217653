import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import Table from 'components/Table';
import TrashButton from 'components/TrashButton';
import { companyRoleToString } from 'helpers/RoleHelper';
import { find, orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { companyUsersStore } from 'stores/CompanyUsersStore';
import { RoleEnum } from 'types/RoleEnum';
import { UserInterface } from 'types/UserInterface';

interface Props {
    companyId: string
}

const UsersTable = observer((props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { companyId } = props;
    useEffect(() => {
        companyUsersStore.getUsers(companyId);

        return () => {
            companyUsersStore.resetStore();
        }
    }, [companyId])

    const onDelete = (e: React.MouseEvent, companyId: string, userId: string) => {
        e.stopPropagation(); // Prevent table click from happening

        if (!window.confirm(t('confirmDelete')!)) return;
        companyUsersStore.deleteUser(companyId, userId);
    }

    const toggleRole = (e: React.MouseEvent, companyId: string, user: UserInterface) => {
        e.stopPropagation(); // Prevent table click from happening

        const companyUser = find(user.companies, c => c.company.id === companyId);
        if (!companyUser) return '';

        let newRole: RoleEnum;
        if (companyUser.role === RoleEnum.BLOCKED) {
            newRole = RoleEnum.USER;
        }
        else if (companyUser.role === RoleEnum.USER) {
            newRole = RoleEnum.ADMIN
        }
        else {
            newRole = RoleEnum.BLOCKED;
        }

        companyUsersStore.updateUserRole(companyId, user.id, newRole);
    }

    const users = companyUsersStore.users;

    return (
        <Table
            onRowClicked={(item) => navigate(generatePath(superAdminRoutes.pageUserDetails, { id: item.id }))}
            defaultSortLabel={t('name')!}
            data={orderBy(users, u => u.name)}
            render={[
                {
                    label: t('name'),
                    cell: (item) => item.name,
                    sortProperty: (item) => item.name
                },
                {
                    label: t('email'),
                    cell: (item) => item.email,
                    sortProperty: (item) => item.email
                },
                {
                    label: t('role'),
                    cell: (item) => {
                        const company = find(item.companies, c => c.company.id === props.companyId);
                        if (!company) return '';

                        return (
                            <div
                                className='cursor-pointer underline'
                                onClick={(e) => toggleRole(e, props.companyId, item)}>
                                {companyRoleToString(company.role)}
                            </div>
                        )
                    },
                    sortProperty: (item) => {
                        const company = find(item.companies, c => c.company.id === props.companyId);
                        if (!company) return '';
                        return companyRoleToString(company.role);
                    },
                },
                {
                    label: '',
                    width: '70',
                    cell: (item) => {
                        return (
                            <div onClick={(e) => onDelete(e, props.companyId, item.id)}>
                                <TrashButton />
                            </div>
                        )
                    }
                }
            ]}
        />
    )

})

export default UsersTable;