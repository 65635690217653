import { FiTrash2 } from 'react-icons/fi';


interface Props {
    disabled?: boolean,
    className?: string,
    onClick?: () => void
}

const TrashButton = (props: Props) => {

    let className = 'p-2 rounded';

    if (props.disabled) {
        className += ' bg-gray-100 text-gray-400';
    }
    else {
        className += ' text-red-400 bg-gray-200 cursor-pointer';
    }

    return (
        <FiTrash2
            className={`${className} ${props.className ? props.className : ''}`}
            size={32}
            strokeWidth={1}
            onClick={props.onClick}
        />
    )
}

export default TrashButton;