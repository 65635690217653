import CompanyUsersTable from 'components/CompanyUsersTable';

const PageUsers = () => {
    return (
        <div>
            <CompanyUsersTable />
        </div>
    )
}

export default PageUsers;