import { useFormContext } from "react-hook-form";
import { get } from 'lodash';

interface Props {
    name: string,
    className?: string
}

const Error = (props: Props) => {
    const { formState } = useFormContext();
    const errorMsg = get(formState.errors, (`${props.name}.message`)) as any;

    return (
        <div className={`text-xs text-red-800 font-medium mt-1 inline ml-1 ${props.className ? props.className : ''}`}>
            {errorMsg}
        </div>
    )
}

export default Error;