import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { languageStore } from 'stores/LanguageStore';
import { LanguageEnum } from 'types/LanguageEnum';
import DaImg from './da.png';
import EnImg from './en.png';
import LanguageIcon from './LanguageIcon';

const ToggleLanguage = observer(() => {
    const { i18n } = useTranslation();
    const language = languageStore.language;

    const onChangeLanguage = (language: LanguageEnum) => {
        languageStore.setLanguage(language);
        i18n.changeLanguage(languageStore.getLanguageString());
    }

    const isVisible = languageStore.languageSwitchVisible;
    if (!isVisible) return null;

    return (
        <div className='fixed bottom-0 right-0 bg-white pt-2 pb-4 px-2 rounded'>
            <div className='flex'>
                <LanguageIcon
                    imagePath={DaImg}
                    alt='Danish'
                    isActive={language === LanguageEnum.DA}
                    onClick={() => onChangeLanguage(LanguageEnum.DA)}
                />
                <LanguageIcon
                    imagePath={EnImg}
                    alt='English'
                    isActive={language === LanguageEnum.EN}
                    onClick={() => onChangeLanguage(LanguageEnum.EN)}
                />
            </div>
        </div>
    )
})

export default ToggleLanguage;