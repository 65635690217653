import Button from 'components/Forms/Button';
import Input from 'components/Forms/Input';
import { useTranslation } from 'react-i18next';
import NotLoggedInWrapper from 'components/NotLoggedInWrapper';
import { useNavigate } from 'react-router-dom';
import { routes } from 'components/Routes';
import Form from 'components/Forms/Form';
import { authStore } from 'stores/AuthStore';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import BackToFrontpageButton from 'components/BackToFrontpageButton';
import { LanguageEnum } from 'types/LanguageEnum';
import CompanyBlock from './CompanyBlock';
import { createUserStore } from 'stores/CreateUserStore';
import { getErrorMessage } from 'helpers/ErrorHelper';

enum FormEnum {
    NAME = 'name',
    EMAIL = 'email',
    PASSWORD = 'password',
    PASSWORD_REPEAT = 'password2',
    LANGUAGE = 'language',
    COMPANY_CODE = 'companyCode',
    COMPANY_NAME = 'companyName'
}

interface FormInterface {
    [FormEnum.NAME]: string,
    [FormEnum.EMAIL]: string,
    [FormEnum.PASSWORD]: string
    [FormEnum.PASSWORD_REPEAT]: string,
    [FormEnum.LANGUAGE]: LanguageEnum,
    [FormEnum.COMPANY_CODE]: string,
    [FormEnum.COMPANY_NAME]: string
}

enum TypeEnum {
    CREATE_NEW_COMPANY,
    JOIN_EXISTING_COMPANY
}

const PageSignUp = () => {
    const [companyType, setCompanyType] = useState(TypeEnum.CREATE_NEW_COMPANY);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (authStore.isLoggedIn) {
            authStore.logout();
        }
    }, [])

    const onSubmit = async (data: FormInterface) => {

        if (data.password !== data.password2) {
            toast(t('passwordVaries'), { type: 'error' });
            return;
        }

        if (companyType === TypeEnum.CREATE_NEW_COMPANY) {
            data.companyCode = '';

            if (!data.companyName) {
                toast(t('pleaseEnterCompanyName'), { type: 'error' });
                return;
            }

        }

        if (companyType === TypeEnum.JOIN_EXISTING_COMPANY) {
            data.companyName = '';

            if (!data.companyCode) {
                toast(t('pleaseEnterCompanyCode'), { type: 'error' });
                return;
            }

        }

        try {
            await createUserStore.createUser({
                companyName: data.companyName,
                email: data.email,
                companyCode: data.companyCode,
                language: data.language,
                name: data.name,
                password: data.password
            })

            toast(t('userCreated'), { type: 'success' });
            navigate(routes.pageLogin);
        }
        catch (error: any) {
            toast(JSON.stringify(error.response.data), { type: 'error' })
        }
    }

    return (
        <NotLoggedInWrapper>
            <Form
                validation={(yup) => ({
                    [FormEnum.NAME]: yup.string().required('*'),
                    [FormEnum.EMAIL]: yup.string().required('*'),
                    [FormEnum.PASSWORD]: yup.string().min(6, t('passwordMinLength')!).required('*'),
                    [FormEnum.PASSWORD_REPEAT]: yup.string().min(6, t('passwordMinLength')!).required('*')
                })}
                onSubmit={onSubmit}>
                <div className='grid gap-4 '>
                    <Input
                        label={t('name')}
                        name={FormEnum.NAME}
                    />
                    <Input
                        type='email'
                        label={t('email')}
                        name={FormEnum.EMAIL}
                    />
                    <Input
                        label={t('password')}
                        name={FormEnum.PASSWORD}
                        type='password'
                    />
                    <Input
                        label={t('passwordRepeat')}
                        name={FormEnum.PASSWORD_REPEAT}
                        type='password'
                    />


                    <h1 className='text-center my-2 text-xl'>
                        {t('company')}
                    </h1>
                    <div className='flex items-center'>
                        <CompanyBlock
                            label={t('createNew')}
                            name={FormEnum.COMPANY_NAME}
                            placeholder={t('company')}
                            className='mr-1'
                            isActive={companyType === TypeEnum.CREATE_NEW_COMPANY}
                            onActivate={() => setCompanyType(TypeEnum.CREATE_NEW_COMPANY)}
                        />

                        <CompanyBlock
                            label={t('joinExisting')}
                            name={FormEnum.COMPANY_CODE}
                            placeholder={t('companyCode')}
                            className='ml-1'
                            isActive={companyType === TypeEnum.JOIN_EXISTING_COMPANY}
                            onActivate={() => setCompanyType(TypeEnum.JOIN_EXISTING_COMPANY)}
                        />

                    </div>

                    <div className='text-center mt-8'>
                        <Button
                            appearance='primary'
                            type='submit'
                            label={t('createUser')}
                        />
                    </div>
                </div>
            </Form>
            <BackToFrontpageButton />
        </NotLoggedInWrapper>
    )
}

export default PageSignUp;