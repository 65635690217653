import Button from 'components/Forms/Button';
import Input from 'components/Forms/Input';
import { useTranslation } from 'react-i18next';
import NotLoggedInWrapper from 'components/NotLoggedInWrapper';
import { useState } from 'react';
import BackToFrontpageButton from '../../BackToFrontpageButton';
import ResetComplete from './ResetComplete';
import Form from 'components/Forms/Form';
import { authStore } from 'stores/AuthStore';

const EMAIL = 'email';

const PageForgotPassword = () => {
    const [resetComplete, setResetComplete] = useState(false);
    const { t } = useTranslation();
    
    const onSubmit = async (data: any) => {
        authStore.resetPassword(data[EMAIL]);
        setResetComplete(true);
    }

    if (resetComplete) return <ResetComplete />
    return (
        <NotLoggedInWrapper>
            <Form
                validation={(yup) => ({
                    [EMAIL]: yup.string().required('*')
                })}
                onSubmit={onSubmit}>
                <div className='flex'>
                    <Input
                        label='Email'
                        name={EMAIL}
                        className='flex-1'
                    />
                    <Button
                        appearance='primary'
                        className='h-10 mt-6 ml-2'
                        type='submit'
                        label={t('resetPassword')}
                    />
                </div>
            </Form>
            <BackToFrontpageButton />
        </NotLoggedInWrapper>
    )
}

export default PageForgotPassword;