interface Props {
    label: string,
    isActive?: boolean,
    onClick: () => void
}

const MenuItem = (props: Props) => {

    let className = 'py-2 my-2 rounded pl-4 cursor-pointer';

    if (props.isActive) {
        className += ' bg-blue-900 text-white'
    }
    else {
        className += ' hover:bg-gray-100'
    }

    return (
        <li className={className} onClick={() => props.onClick()}>
            {props.label}
        </li>
    )
}

export default MenuItem;