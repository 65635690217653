import { ErrorResponse } from '@remix-run/router';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment";
import { baseRequestStore } from '../stores/BaseRequestStore';

export class BaseRequest {

    request: AxiosInstance;

    constructor(opts: {
        baseUrl: string,
        clientKey: string,
        handleRenewToken?: {
            onHandleRenewToken: () => Promise<any>,
            onFailure: () => void
        },
        successResponse?: (response: AxiosResponse) => void,
        errorResponse?: (error: AxiosError) => void
    }) {
        this.request = axios.create({
            baseURL: opts.baseUrl,
            headers: { 'client-key': opts.clientKey }
        })

        this.request.interceptors.request.use(async (config) => {

            // check if we need to renew token
            // 
            if (opts.handleRenewToken) {
                await this.handleRenewToken(config, opts.handleRenewToken.onHandleRenewToken, opts.handleRenewToken.onFailure);
            }

            if (baseRequestStore.bearerToken) {
                (config.headers as any)['Authorization'] = 'bearer ' + baseRequestStore.bearerToken;
            }
            return config;
        })

        this.handleRequestResponse(opts.errorResponse);

    }

    handleRequestResponse = (errorResponse?: (error: AxiosError) => void) => {
        // On requests
        this.request.interceptors.request.use(axiosRequest => {
            baseRequestStore.addRequest();
            return axiosRequest;
        })

        // On responses
        this.request.interceptors.response.use(
            (response) => {
                baseRequestStore.removeRequest();
                return response;
            },
            (error) => {
                baseRequestStore.removeRequest();
                if (errorResponse) {
                    return errorResponse(error);
                }
                else {
                    return Promise.reject(error);
                }
            }
        )
    }

    handleRenewToken = async (config: AxiosRequestConfig, onHandleRenewToken: () => Promise<any>, onFailure: () => void) => {
        if (
            (
                // when current token is expired or expire date is invalid
                !moment(baseRequestStore.expires).isValid() ||
                moment(baseRequestStore.expires).isSameOrBefore(moment())
            )
            && config.url !== 'auth/renew-token'
            && baseRequestStore.renewToken
        ) {
            try {
                await onHandleRenewToken();
            } catch (err) {
                onFailure();
            }
        }
    }
}