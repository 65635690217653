import { getErrorMessage } from 'helpers/ErrorHelper';
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { userDetailsStore } from 'stores/UserDetailsStore';

const DeleteMe = () => {
    const { t } = useTranslation();

    const onDelete = async () => {
        if (!window.confirm(t('confirmUserDelete')!)) return;

        try {
            await userDetailsStore.deleteMe();
            toast(t('userDeleted'), { type: 'success' });
            authStore.logout();
        }
        catch (error) {
            toast(getErrorMessage(error), { type: 'error' });
        }
    }

    return (
        <div className='mt-12 text-right'>
            <button
                onClick={() => onDelete()}
                className='underline text-gray-400'
                type='button'>
                {t('delete')} {t('user').toLowerCase()}
            </button>
        </div>
    )

}

export default DeleteMe;