import Button from 'components/Forms/Button';
import FormSection from 'components/FormSection';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SchemaFormEnum } from 'types/SchemaFormEnum';

const FormSections = () => {
    const { t } = useTranslation();
    const { control, getValues, resetField } = useFormContext();

    const { fields, append, move, remove } = useFieldArray({
        control: control,
        name: SchemaFormEnum.sections
    });

    const addSection = () => {
        append({
            title: '',
            description: '',
            items: [{
                title: ''
            }]
        }, { shouldFocus: false })
    }

    const deleteSection = (index: number) => {
        remove(index);
    }

    /**
     * In theory we should just be able to move locations of form items using move method
     * on useFieldArray. However there is a bug in react hook form, which prevents all subitems
     * to be shown. It means that if we have two sections that have a different number of items
     * Not all will be shown when switching places. Instead we have to manually move the sections
     * and resetFields on the subItems, to make sure they are properly swapped.
     * 
     * More info: https://app.asana.com/0/inbox/717673821687419/1204013670885897/1204015434035833
     */
    const moveUp = (index: number) => {
        const itemsToBeMovedUp = getValues(`${SchemaFormEnum.sections}.${index}.${SchemaFormEnum.items}`);
        const itemsToBeMovedDown = getValues(`${SchemaFormEnum.sections}.${index - 1}.${SchemaFormEnum.items}`);

        move(index, index - 1);
        
        resetField(`${SchemaFormEnum.sections}.${index - 1}.${SchemaFormEnum.items}`, {
            defaultValue: itemsToBeMovedUp
        })

        resetField(`${SchemaFormEnum.sections}.${index}.${SchemaFormEnum.items}`, {
            defaultValue: itemsToBeMovedDown
        })
    }

    const moveDown = (index: number) => {
        const itemsToBeMovedUp = getValues(`${SchemaFormEnum.sections}.${index}.${SchemaFormEnum.items}`);
        const itemsToBeMovedDown = getValues(`${SchemaFormEnum.sections}.${index + 1}.${SchemaFormEnum.items}`);

        move(index, index + 1);
        
        resetField(`${SchemaFormEnum.sections}.${index + 1}.${SchemaFormEnum.items}`, {
            defaultValue: itemsToBeMovedUp
        })

        resetField(`${SchemaFormEnum.sections}.${index}.${SchemaFormEnum.items}`, {
            defaultValue: itemsToBeMovedDown
        })
    }

    return (
        <div>
            {
                fields.map((item, index) => {
                    return (
                        <FormSection
                            key={`${SchemaFormEnum.sections}[${index}]`}
                            formKey={`${SchemaFormEnum.sections}[${index}]`}
                            index={index}
                            onDelete={fields.length > 1 ? () => deleteSection(index) : undefined}
                            onMoveDown={fields.length > 1 && index < fields.length - 1 ? () => moveDown(index) : undefined}
                            onMoveUp={fields.length > 1 && index > 0 ? () => moveUp(index) : undefined}
                        />
                    )
                })
            }
            <div className='text-center'>
                <Button
                    onClick={() => addSection()}
                    type='button'
                    appearance='secondary'
                    label={t('addSection')}
                />
            </div>
        </div>
    )
}

export default FormSections;