import { action, makeObservable, observable } from 'mobx';
import { FormSubmissionInterface } from 'types/FormSubmissionInterface';
import { PagingResultInterface } from 'types/PagingResultInterface';
import { BaseStore } from './BaseStore';

class FormSubmissionStore extends BaseStore {

    submissions: PagingResultInterface<FormSubmissionInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            submissions: observable,

            resetStore: action,
            getSubmissions: action,
            deleteSubmission: action
        })
    }

    resetStore = () => {
        this.submissions = null;
    }

    getSubmissions = (formId: string) => {
        return this.getPaged(`forms/${formId}/submissions`, this.submissions, 'submissions');
    }

    deleteSubmission = (formId: string, submissionId: string) => {
        return this.delete(`forms/${formId}/submissions/${submissionId}`, submissionId, this.submissions?.items)
    }

    exportSubmissionById = (formId: string, submissionId: string, downloadToken: string) => {
        window.open([
            `${process.env.REACT_APP_API_BASE_URL}/forms/${formId}/submissions/${submissionId}/export`,
            `?clientkey=${process.env.REACT_APP_CLIENT_KEY}`,
            `&token=${downloadToken}`
        ].join(''))
    }

    exportSubmissions = (formId: string, downloadToken: string) => {
        window.open([
            `${process.env.REACT_APP_API_BASE_URL}/forms/${formId}/submissions/export`,
            `?clientkey=${process.env.REACT_APP_CLIENT_KEY}`,
            `&token=${downloadToken}`
        ].join(''))
    }

}

export const formSubmissionStore = new FormSubmissionStore();