import _ from 'lodash';
import { useEffect } from 'react';
import { languageStore } from 'stores/LanguageStore';
import { FormDetailsInterface } from 'types/FormDetailsInterface';
import css from './style.module.scss';

interface Props {
    details: FormDetailsInterface
}

const FormDetails = (props: Props) => {
    const { details } = props;
    const sections = _.orderBy(details.sections, s => s.position);

    useEffect(() => {
        // Forms are dependent on the language selected
        // To make sure users don't get confused, 
        // hide the languageSwitcher any time form related content is visible
        languageStore.setLanguageSwitchVisible(false);

        return () => {
            languageStore.setLanguageSwitchVisible(true);
        }
    }, [])

    return (
        <div className='border p-8'>
            {
                sections.map((section, sectionIndex) => {
                    const items = _.orderBy(section.items, i => i.position);
                    return (
                        <div key={sectionIndex} className='my-8'>
                            <h1
                                className='text-xl mb-4 flex'>
                                <span className='w-[50px]'>
                                    {sectionIndex + 1}.
                                </span>
                                <span>
                                    {section.title}
                                </span>
                            </h1>
                            <hr style={{ marginTop: '-10px' }} className='mb-4' />
                            {
                                section.description &&
                                <div className='my-4 py-8 px-4'>
                                    <div
                                        className={css.sectionDescription}
                                        dangerouslySetInnerHTML={{ __html: section.description }}
                                    />
                                </div>
                            }

                            {
                                items.map((item, itemIndex) => {
                                    return (
                                        <div key={itemIndex} className='my-1 flex'>
                                            <div className='w-[50px]'>
                                                {sectionIndex + 1}.{itemIndex + 1}
                                            </div>
                                            <div>
                                                {item.title}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )

}

export default FormDetails;