import CompaniesTable from 'components/SuperAdmin/CompaniesTable';

const PageCompanies = () => {
    return (
        <div>
            <CompaniesTable />
        </div>
    )
}

export default PageCompanies;