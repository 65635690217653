import Label from "components/Forms/Label";
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { FormElementInterface } from 'types/FormElementInterface';

interface Props extends FormElementInterface {
    type?: 'text' | 'password' | 'checkbox' | 'email',
    defaultChecked?: boolean,
    className?: string
}

const Checkbox = (props: Props) => {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, []);

    return (
        <div className='flex items-center'>
            <input
                id={props.name}
                className={`border p-2 rounded-md w-auto mr-2`}
                {...register(props.name)}
                type='checkbox'
                defaultChecked={props.defaultChecked}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
            />
            <Label name={props.name} label={props.label} />
        </div>
    )
}

export default Checkbox;