import TitleWithBackArrow from 'components/TitleWithBackArrow';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { userDetailsStore } from 'stores/UserDetailsStore';
import { LanguageEnum } from 'types/LanguageEnum';
import DetailItem from './DetailItem';
import UserCompaniesTable from './UserCompaniesTable';

const PageUserDetails = observer(() => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            userDetailsStore.getUser(id);
        }

        return () => {
            userDetailsStore.resetStore();
        }
    }, [id])

    const user = userDetailsStore.user;
    if (!user) return null;

    return (
        <div>
            <div className='mb-8'>
                <TitleWithBackArrow title={t('user')} />
            </div>

            <div className='mx-12'>
                <dl className='w-full overflow-hidden'>
                    <DetailItem
                        label={t('name')}
                        value={`${user.name} ${user.isa ? '🦸‍♂️' : ''}`}
                    />
                    <DetailItem
                        label={t('email')}
                        value={user.email}
                    />
                    <DetailItem
                        label={t('language')}
                        value={user.language === LanguageEnum.DA ? t('danish') : t('english')}
                    />
                    <DetailItem
                        label={t('lastSeen')}
                        value={
                            user.lastSeen
                                ? moment(user.lastSeen).format('DD/MM-YYYY HH:mm')
                                : '-'
                        }
                    />
                </dl>

                <div className='my-16'>
                    <UserCompaniesTable companies={user.companies} />
                </div>
            </div>

        </div>
    )
})

export default PageUserDetails;