import Select from 'components/Forms/Select';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { superadminCategoriesStore } from 'stores/SuperadminCategoriesStore';

interface Props {
    name: string;
}

const SelectCategory = observer((props: Props) => {
    const { t } = useTranslation();
    const { getValues } = useFormContext();

    useEffect(() => {
        superadminCategoriesStore.getCategories();

        return () => {
            superadminCategoriesStore.resetStore();
        }
    }, [])

    const categories = superadminCategoriesStore.categories;
    if (!categories) return null;

    return (
        <div className='text-sm px-3 py-1 border rounded'>
            <Select
                defaultValue={getValues(props.name)}
                name={props.name}
                placeholder={`-- ${t('noCategorySelected') || ''} --`}
                options={categories.map(c => ({ value: c.name, id: c.id! }))}
            />
        </div>
    )
})

export default SelectCategory;