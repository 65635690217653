import { action, makeObservable, observable } from 'mobx';
import { LanguageEnum } from 'types/LanguageEnum';
import SaveableStore from './SaveableStore';

class LanguageStore extends SaveableStore {

    language: LanguageEnum = LanguageEnum.DA;
    languageSwitchVisible: boolean = true;

    constructor() {
        super('LanguageStore');
        
        makeObservable(this, {
            language: observable,
            languageSwitchVisible: observable,

            setLanguage: action,
            setLanguageSwitchVisible: action
        })

        this.initSessionStorage(this, ['language']);
    }

    setLanguage = (language: LanguageEnum) => {
        this.language = language;
    }

    getLanguageString = () => {
        switch(this.language) {
            case LanguageEnum.DA: return 'da';
            case LanguageEnum.EN: return 'en';
            default: return 'da';
        }
    }

    setLanguageSwitchVisible = (value: boolean) => {
        this.languageSwitchVisible = value;
    }

}

export const languageStore = new LanguageStore();