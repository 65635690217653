import { initReactI18next } from 'react-i18next';
import i18next from "i18next";
import englishJson from './en.json';
import danishJson from './da.json';
import { languageStore } from 'stores/LanguageStore';

export const loadLanguage = () => {
    i18next
        .use(initReactI18next)
        .init({
            resources: {
                en: { translation: englishJson },
                da: { translation: danishJson }
            },
            lng: languageStore.getLanguageString()
        })
}