import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SchemaFormEnum } from 'types/SchemaFormEnum';

interface Props {
    defaultValue?: string
}

const SelectIsDraft = (props: Props) => {
    const { t } = useTranslation();
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(SchemaFormEnum.isDraft)
        }
    }, []);

    return (
        <select
            className='border h-9 px-2 mr-6 rounded'
            {...register(SchemaFormEnum.isDraft)}
            defaultValue={props.defaultValue}>

            <option value='true'>
                {t('draft')}
            </option>

            <option value='false'>
                {t('published')}
            </option>

        </select>
    )
}

export default SelectIsDraft;