import { routes } from 'components/Routes';
import { superAdminRoutes } from 'components/Routes/SuperAdmin';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authStore } from 'stores/AuthStore';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import { CompanyInterface } from 'types/CompanyInterface';
import { RoleEnum } from 'types/RoleEnum';
import PageNoAdminCompanies from './PageNoAdminCompanies';

const PageSelectUserCompany = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userCompanies } = selectedCompanyStore;

    let adminCompanies = userCompanies.filter(uc => uc.role === RoleEnum.ADMIN);
    adminCompanies = _.orderBy(adminCompanies, ac => ac.company.name);
    const isSuperAdmin = authStore.user?.isa;

    useEffect(() => {
        if (adminCompanies.length === 1 && !isSuperAdmin) {
            selectedCompanyStore.setSelectedCompany(adminCompanies[0].company);
            navigate(routes.pageFront);
        }
    }, [adminCompanies, navigate, isSuperAdmin])

    const setSelectedCompany = (company: CompanyInterface) => {
        selectedCompanyStore.setSelectedCompany(company);
        navigate(routes.pageFront);
    }

    const signInAsSuperAdmin = () => {
        selectedCompanyStore.setSelectedCompany(null);
        selectedCompanyStore.setLoggedInAsSuperAdmin(true);
        navigate(superAdminRoutes.pageFront);
    }

    if (adminCompanies.length === 0 && !isSuperAdmin) {
        return <PageNoAdminCompanies />
    }

    return (
        <div className='
            mx-auto 
            w-full 
            p-12 
            lg:p-0 
            lg:w-96 
            mt-32
            '>
            <div>
                <h1 className='text-2xl mb-12 pt-4'>
                    {t('selectCompany')}
                </h1>
                <ul>
                    {
                        isSuperAdmin &&
                        <li 
                            onClick={() => signInAsSuperAdmin()}
                            className='
                            bg-slate-100
                            my-4 
                            cursor-pointer 
                            hover:bg-gray-50 
                            px-4 
                            py-4 
                            rounded 
                            shadow-sm'>
                            🦸‍♂️ Superadmin
                        </li>
                    }
                    {
                        adminCompanies.map((ac, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={() => setSelectedCompany(ac.company)}
                                    className='
                                    bg-white 
                                    my-4 
                                    cursor-pointer 
                                    hover:bg-gray-50 
                                    px-4 
                                    py-4 
                                    rounded 
                                    shadow-sm'>
                                    {ac.company.name}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
})

export default PageSelectUserCompany;