import { Tooltip } from '@mui/material';
import ArrowButton, { ArrowButtonDirection } from 'components/ArrowButton';
import Error from 'components/Forms/Error';
import InputHidden from 'components/Forms/InputHidden';
import FormSectionItems from 'components/FormSectionItems';
import HtmlEditor from 'components/HtmlEditor';
import TrashButton from 'components/TrashButton';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SchemaFormEnum } from 'types/SchemaFormEnum';

interface Props {
    formKey: string,
    index: number,
    onMoveUp?: () => void,
    onMoveDown?: () => void,
    onDelete?: () => void
}

const FormSection = (props: Props) => {
    const { t } = useTranslation();
    const { register, unregister, formState } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(`${props.formKey}.${SchemaFormEnum.title}`)
        }
    }, []);

    const name = `${props.formKey}.${SchemaFormEnum.title}`;
    const errorMsg = get(formState.errors, (`${name}.message`)) as any;

    return (
        <div key={props.index}>
            <div className='mt-8 mb-8 border-b p-8 pr-0'>
                <div className='flex'>
                    <div className='w-[70px]'>
                        <h2 className='text-3xl pt-1'>{props.index + 1}.</h2>
                    </div>
                    <div className='flex-1'>
                        <div className='flex items-center'>
                            <InputHidden name={SchemaFormEnum.id} />
                            <input
                                className={`flex-1 border p-2 w-full rounded-md ${errorMsg ? 'border-red-800' : ''}`}
                                {...register(name)}
                                type='text'
                                placeholder='Overskrift'
                            />
                        </div>
                    </div>
                </div>
                <div className='flex'>
                    <div className='w-[70px] pt-20'>
                        <Tooltip title={t('moveUp')} disableHoverListener={props.onMoveUp === undefined}>
                            <span>
                                <ArrowButton
                                    className='mb-2'
                                    direction={ArrowButtonDirection.UP}
                                    onClick={props.onMoveUp}
                                    disabled={props.onMoveUp === undefined}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title={t('moveDown')} disableHoverListener={props.onMoveDown === undefined}>
                            <span>
                                <ArrowButton
                                    className='mb-6'
                                    direction={ArrowButtonDirection.DOWN}
                                    onClick={props.onMoveDown}
                                    disabled={props.onMoveDown === undefined}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title={t('delete')} disableHoverListener={props.onDelete === undefined}>
                            <span>
                                <TrashButton
                                    disabled={props.onDelete === undefined}
                                    onClick={props.onDelete}
                                />
                            </span>
                        </Tooltip>
                    </div>
                    <div className='flex-1'>
                        <HtmlEditor
                            name={`${props.formKey}.${SchemaFormEnum.description}`}
                            className='h-64 mt-2'
                        />
                    </div>
                </div>

                <div>
                    <FormSectionItems formSectionIndex={props.index} />
                    {/* <FormSectionItem /> */}
                </div>

            </div>
        </div>
    )

}

export default FormSection;