import { routes } from 'components/Routes';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import { RoleEnum } from 'types/RoleEnum';
import { FiChevronDown } from 'react-icons/fi';

const CompanyName = observer(() => {
    const navigate = useNavigate();

    const { userCompanies } = selectedCompanyStore;
    let adminCompanies = userCompanies.filter(uc => uc.role === RoleEnum.ADMIN);
    adminCompanies = _.orderBy(adminCompanies, ac => ac.company.name);
    const canSwitchCompany = adminCompanies.length > 1;

    const onSwitchCompany = () => {
        if (!canSwitchCompany) return;
        navigate(routes.pageSelectUserCompany)
    }

    const company = selectedCompanyStore.selectedCompany;
    if (!company) return null;

    return (
        <div
            onClick={() => onSwitchCompany()}
            className={`
                flex items-center 
                text-lg 
                text-gray-800 
                mr-5
                ${canSwitchCompany ? 'cursor-pointer' : ''}
            `}
            style={{ paddingTop: '4px' }}>
            {company?.name}
            {
                canSwitchCompany &&
                <FiChevronDown className='ml-2' />
            }
        </div>
    )

})

export default CompanyName;