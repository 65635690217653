import PageForgotPassword from 'components/Pages/PageForgotPassword';
import PageResetPassword from 'components/Pages/PageResetPassword';
import ToggleLanguage from 'components/ToggleLanguage';
import { createBrowserRouter, Route, Routes } from 'react-router-dom';
import PageFront from '../Pages/PageFront';
import PageLogin from '../Pages/PageLogin';
import ToastContainer from 'components/ToastContainer';
import LoggedInWrapper from 'components/LoggedInWrapper';
import PageFormCreate from 'components/Pages/PageFormCreate';
import PageFormEdit from 'components/Pages/PageFormEdit';
import PageUsers from 'components/Pages/PageUsers';
import PageCompany from 'components/Pages/PageCompany';
import PageSelectUserCompany from 'components/Pages/PageSelectUserCompany';
import PageFormDetails from 'components/Pages/PageFormDetails';
import PageFormSubmissions from 'components/Pages/PageFormSubmissions';
import PageSignUp from 'components/Pages/PageSignUp';
import HideLanguageToggle from 'components/HideLanguageToggle';
import SuperAdminRoutes from './SuperAdmin';
import PageMyProfile from 'components/Pages/PageMyProfile';

export const routes = {
    pageFront: '/',
    pageLogin: '/login',
    pageSignUp: '/signup',
    pageForgotPassword: '/forgot-password',
    pageResetPassword: '/reset-password/:token',
    pageFormCreate: '/forms/create',
    pageFormDetails: '/forms/:id',
    pageFormEdit: '/forms/:id/edit',
    pageFormSubmissions: '/forms/:id/submissions',
    pageUsers: '/users',
    pageCompany: '/company',
    pageSelectUserCompany: '/select-company',
    pageMyProfile: '/profile'
}

const MyRoutes = () => {

    return (
        <div>
            <ToastContainer position='top-center' autoClose={2000} />
            <ToggleLanguage />
            <Routes>
                <Route path={routes.pageLogin} element={<PageLogin />} />
                <Route path={routes.pageSignUp} element={<PageSignUp />} />
                <Route path={routes.pageForgotPassword} element={<PageForgotPassword />} />
                <Route path={routes.pageResetPassword} element={<PageResetPassword />} />
                <Route path={routes.pageSelectUserCompany} element={<PageSelectUserCompany />} />

                <Route element={<LoggedInWrapper />}>
                    <Route path={routes.pageFront} element={<PageFront />} />

                    <Route path={routes.pageUsers} element={<PageUsers />} />
                    <Route path={routes.pageCompany} element={<PageCompany />} />
                    <Route path={routes.pageMyProfile} element={<PageMyProfile />} />

                    <Route element={<HideLanguageToggle />}>
                        <Route path={routes.pageFormCreate} element={<PageFormCreate />} />
                        <Route path={routes.pageFormDetails} element={<PageFormDetails />} />
                        <Route path={routes.pageFormEdit} element={<PageFormEdit />} />
                        <Route path={routes.pageFormSubmissions} element={<PageFormSubmissions />} />
                    </Route>

                </Route>
            </Routes>
            <SuperAdminRoutes />
        </div>
    )
}

// router can be used to navigate outside react components
// https://github.com/remix-run/react-router/issues/9422#issuecomment-1302564759
export const router = createBrowserRouter([
    { path: "*", element: <MyRoutes /> }
])