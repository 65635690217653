import NotLoggedInWrapper from 'components/NotLoggedInWrapper';
import { useTranslation } from 'react-i18next';
import BackToFrontpageButton from '../../../BackToFrontpageButton';

const ResetComplete = () => {
    const { t } = useTranslation();

    return (
        <NotLoggedInWrapper>
            <div className='text-center'>
                <p>
                    {t('resetPasswordRequestEmailSent')} 😊
                </p>
                <BackToFrontpageButton />
            </div>
        </NotLoggedInWrapper>
    )
}

export default ResetComplete;