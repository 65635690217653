import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { formDetailsStore } from 'stores/FormDetailsStore';
import { ResponsiveLine } from "@nivo/line";
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { area } from 'd3-shape';

interface Props {
    formId: string
}

const AreaGreen = ({ series, xScale, yScale, innerHeight }: any) => {
    if (!series[0]?.data) return null;
    const areaGenerator = area()
        .x0((d: any) => xScale(d.data.x))
        .y1((d: any) => 84)

    return (
        <path
            d={areaGenerator(series[0].data)!}
            fill='green'
            fillOpacity={0.6}
            opacity={0.6}
        />
    );
};

const AreaYellow = ({ series, xScale, yScale, innerHeight }: any) => {
    if (!series[0]?.data) return null;
    const areaGenerator = area()
        .x0((d: any) => xScale(d.data.x))
        .y1((d: any) => 126)

    return (
        <path
            d={areaGenerator(series[0].data)!}
            fill='yellow'
            fillOpacity={0.6}
            opacity={0.6}
        />
    );
};

const AreaRed = ({ series, xScale, yScale, innerHeight }: any) => {
    if (!series[0]?.data) return null;
    const areaGenerator = area()
        .x0((d: any) => xScale(d.data.x))
        .y1((d: any) => innerHeight)

    return (
        <path
            d={areaGenerator(series[0].data)!}
            fill='red'
            fillOpacity={0.6}
            opacity={0.6}
        />
    );
};


const SafetyIndexGraph = observer((props: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        formDetailsStore.getSafetyIndexes(props.formId);

        return () => {
            formDetailsStore.resetSafetyIndexes();
        }
    }, [])

    const data = _.orderBy(formDetailsStore.safetyIndexes, i => i.createdOn);

    return (
        <div>
            <div className="w-full h-[500px] border rounded p-4">
                <ResponsiveLine
                    data={data.length > 0 ? [{
                        id: 'Index',
                        data: data.map((safetyIndex, index) => ({
                            x: moment(safetyIndex.createdOn).format('YYYY-MM-DD'),
                            y: Math.round(safetyIndex.percentage)
                        }))
                    }] : []}
                    margin={{ top: 20, right: 10, bottom: 25, left: 30 }}
                    xScale={{
                        type: "time",
                        format: '%Y-%m-%d',
                        precision: 'day',
                        useUTC: false
                    }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{
                        type: "linear",
                        min: 0,
                        max: 100,
                        stacked: false,
                        reverse: false,
                    }}
                    yFormat={(value) => `${value}%`}
                    curve="linear"
                    pointSize={4}
                    axisBottom={{
                        format: (value) => {
                            return `${t('week')} ${moment(value).week()}`;
                        },
                        tickValues: "every week"
                    }}
                    enableGridX={false}
                    pointBorderWidth={4}
                    lineWidth={2}
                    layers={[
                        'grid',
                        'markers',
                        'areas',
                        AreaRed,
                        AreaYellow,
                        AreaGreen,
                        'lines',
                        'slices',
                        'axes',
                        'points',
                        'legends',
                    ]}
                    // enablePointLabel={true}
                    // pointLabel={x => x.xFormatted.toString()}
                    pointBorderColor='#545454'
                    colors={['#545454']}
                />
            </div>
        </div>
    )

})

export default SafetyIndexGraph;