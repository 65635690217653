import FormDetails from 'components/FormDetails';
import Button from 'components/Forms/Button';
import { routes } from 'components/Routes';
import SafetyIndexGraph from 'components/SafetyIndexGraph';
import TitleWithBackArrow from 'components/TitleWithBackArrow';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formDetailsStore } from 'stores/FormDetailsStore';

const PageFormDetails = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        formDetailsStore.getForm(id!);

        return () => {
            formDetailsStore.resetStore();
        }
    }, [])

    const onShareForm = async (formId: string) => {
        const companyCode = window.prompt(t('enterCompanyCodeOfCompanyYouWantToShareWith')!, '');
        if (!companyCode) return;

        try {
            await formDetailsStore.shareForm(formId, companyCode);
            toast(t('shared'), { type: 'success' });
        }
        catch(error) {
            toast(t('companyNotFound'), { type: 'error'});
        }

    }

    const form = formDetailsStore.form;
    if (!form) return null;

    return (
        <div>
            <div className='flex mt-3 items-center'>
                <TitleWithBackArrow title={form.title} />
                <div className='text-right flex items-center'>
                    <Button
                        className='mr-6'
                        appearance='secondary'
                        type='button'
                        onClick={() => navigate(generatePath(routes.pageFormEdit, { id: id }))}
                        label={t('editForm')}
                    />
                    <Button
                        className='mr-6'
                        onClick={() => navigate(generatePath(routes.pageFormSubmissions, { id: id }))}
                        appearance='secondary'
                        type='button'
                        label={t('downloadReports')}
                    />
                    <Button
                        onClick={() => onShareForm(id!)}
                        appearance='secondary'
                        type='button'
                        label={`${t('share')} ${t('form').toLowerCase()}`}
                    />
                </div>
            </div>
            <div className='mt-8'>
                <SafetyIndexGraph formId={id!} />
            </div>

            <div className='mt-4'>
                <FormDetails details={form} />
            </div>

        </div>

    )

})

export default PageFormDetails;