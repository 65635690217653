import { action, makeObservable, observable } from 'mobx';
import { CompanyInterface } from 'types/CompanyInterface';
import { BaseStore } from './BaseStore';

class CompanyDetailsStore extends BaseStore {

    company: CompanyInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            company: observable,

            resetStore: action,
            getCompany: action,
            updateCompany: action,
            deleteCompany: action
        })
    }

    resetStore = () => {
        this.company = null;
    }

    getCompany = (id: string) => {
        return this.get(`companies/${id}`, 'company');
    }

    updateCompany = (company: CompanyInterface) => {
        return this.put(`companies/${company.id}`, company, this.company);
    }

    deleteCompany = (id: string) => {
        return this.delete(`companies/${id}`, id, this.company);
    }

}

export const companyDetailsStore = new CompanyDetailsStore();