import Label from "components/Forms/Label";
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { FormElementInterface } from 'types/FormElementInterface';
import { get } from 'lodash';

interface Props extends FormElementInterface {
    type?: 'text' | 'password' | 'checkbox' | 'email',
    defaultChecked?: boolean,
    className?: string
    autoComplete?: string;
}

const Input = (props: Props) => {
    const { register, unregister, formState } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(props.name)
        }
    }, []);

    const errorMsg = get(formState.errors, (`${props.name}.message`)) as any;
    
    return (
        <div className={props.className}>
            <Label name={props.name} label={props.label} />
            <input
                className={`border p-2 w-full rounded-md ${props.type === 'checkbox' ? 'w-auto ml-4' : ''} ${errorMsg ? 'border-red-800' : ''}`}
                {...register(props.name)}
                type={props.type || 'text'}
                defaultChecked={props.defaultChecked}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete}
            />
        </div>
    )
}

export default Input;