import Input from 'components/Forms/Input';
import { FiCheckCircle } from 'react-icons/fi';

interface Props {
    name: string,
    isActive?: boolean,
    label: string,
    placeholder: string,
    onActivate: () => void,
    className?: string
}

const CompanyBlock = (props: Props) => {

    const onClick = () => {
        if (!props.isActive) {
            props.onActivate();
        }
    }

    return (
        <div
            onClick={() => onClick()} 
            className={`
            bg-white 
                border-2 
                ${props.isActive ? 'border-blue-900' : 'border'}
                p-4 
                rounded 
                flex-1 
                ${!props.isActive ? 'opacity-70' : ''}
                ${!props.isActive ? 'cursor-pointer' : ''}
                ${props.className || ''}`}
            >
            <div className='flex items-center text-sm font-semibold'>
                {
                    props.isActive &&
                    <FiCheckCircle className='mr-2 text-blue-900' />
                }
                {props.label}
            </div>
            <Input
                className='mt-4'
                label=''
                placeholder={props.placeholder}
                name={props.name}
            />
        </div>
    )
}

export default CompanyBlock;