import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { companyCategoriesStore } from 'stores/CompanyCategoriesStore';
import { superadminCategoriesStore } from 'stores/SuperadminCategoriesStore';
import { CategoryDto } from 'types/CategoryDto';
import { CompanyInterface } from 'types/CompanyInterface';

interface Props {
    company: CompanyInterface;
}

const CompanyCategories = observer((props: Props) => {
    const { t } = useTranslation();
    const [isLoaded, setIsloaded] = useState(false);
    const { company } = props;

    useEffect(() => {
        const getData = async () => {
            await superadminCategoriesStore.getCategories();
            await companyCategoriesStore.getCategories(company.id)
            setIsloaded(true);
        }

        getData();

        return () => {
            companyCategoriesStore.resetStore();
        }
    }, [])

    const toggleCategory = async (companyId: string, categoryId: string, isSelected: boolean) => {
        if (isSelected) {
            await companyCategoriesStore.deleteCategory(companyId, categoryId)
        }
        else {
            await companyCategoriesStore.createCategory(companyId, categoryId)
        }

        toast.success(t('saved'));
    }

    if (!isLoaded) return null;
    if (superadminCategoriesStore.categories.length === 0) return null;
    
    return (
        <div className='border-t pt-8 mt-6'>
            <h1 className='text-2xl'>
                {t('favoriteTemplateCategories')}
            </h1>
            <p className='py-4'>
                {t('favoriteTemplateCategoriesDescription')}.
            </p>
            <div className='flex items-center gap-4 flex-wrap pt-4'>
                {
                    _.orderBy(superadminCategoriesStore.categories, c => c.name?.toLowerCase()).map(superadminCat => {
                        const isSelected = _.some(companyCategoriesStore.categories, (companyCat: CategoryDto) => companyCat.id === superadminCat.id );

                        return (
                            <button 
                                key={crypto.randomUUID()}
                                type="button" 
                                onClick={() => toggleCategory(company.id, superadminCat.id!, isSelected)}
                                className={`rounded-lg px-4 py-1 ${isSelected ? 'bg-green-200' : 'bg-gray-100'}`}>
                            {superadminCat.name}
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
})

export default CompanyCategories;