import Table from 'components/Table';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { formSubmissionStore } from 'stores/FormSubmissionStore';
import TrashButton from 'components/TrashButton';
import { authStore } from 'stores/AuthStore';
import Button from 'components/Forms/Button';

interface Props {
    formId: string
}

const FormSubmissionsTable = observer((props: Props) => {
    const [dataIsLoaded, setDataIsLoaded] = useState(false);

    const { t } = useTranslation();

    const formId = props.formId;
    useEffect(() => {
        const getData = async () => {
            await formSubmissionStore.getSubmissions(formId);
            setDataIsLoaded(true);
        }

        getData();

        return () => {
            formSubmissionStore.resetStore();
        }
    }, [formId])

    const onDelete = (e: MouseEvent, formId: string, submissionId: string) => {
        e.stopPropagation(); // Prevent table click from activating

        if (!window.confirm(t('confirmDelete')!)) return;
        formSubmissionStore.deleteSubmission(formId, submissionId);
    }

    const onDownloadFormSubmission = async (formId: string, submissionId: string) => {
        const downloadToken = await authStore.getDownloadToken() as string;
        formSubmissionStore.exportSubmissionById(formId, submissionId, downloadToken);
    }

    const submissions = _.orderBy(formSubmissionStore.submissions?.items, i => i.createdOn, 'desc') || [];

    return (
        <>
            <Table
                data={submissions}
                onRowClicked={(item) => onDownloadFormSubmission(formId, item.id)}
                render={[
                    {
                        label: t('date'),
                        cell: (item) => moment(item.createdOn).format('DD-MM-YYYY HH:mm')
                    },
                    {
                        label: t('workplace'),
                        cell: (item) => item.workplace
                    },
                    {
                        label: t('section'),
                        cell: (item) => item.section
                    },
                    {
                        label: '',
                        width: '70',
                        cell: (item) => {
                            return (
                                <div onClick={(e) => onDelete(e, formId, item.id)}>
                                    <TrashButton />
                                </div>
                            )
                        }
                    }
                ]}
            />

            {
                (dataIsLoaded && submissions.length === 0) &&
                <div className='text-center mt-6 italic text-sm text-gray-500'>
                    {t('thereAreNoReports')}...
                </div>
            }

            {
                formSubmissionStore.submissions?.pagination.hasNextPage &&
                <div className='text-center mt-6'>
                    <Button
                        appearance='primary'
                        type='button'
                        label={t('loadMore')}
                        onClick={() => formSubmissionStore.getSubmissions(formId)}
                    />
                </div>
            }
        </>
    )

})

export default FormSubmissionsTable;