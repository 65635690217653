import { useFormContext } from "react-hook-form";
import { FormElementInterface } from 'types/FormElementInterface';

const InputHidden = (props: FormElementInterface) => {
    const { register } = useFormContext();
    return (
        <input type="hidden" {...register(props.name)} defaultValue={props.defaultValue} />
    )
}

export default InputHidden;