import { getErrorMessage } from 'helpers/ErrorHelper';
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { companyDetailsStore } from 'stores/CompanyDetailsStore';

interface Props {
    companyId: string
}

const DeleteCompany = (props: Props) => {
    const { t } = useTranslation();

    const onDelete = async (companyId: string) => {
        if (!window.confirm(t('confirmCompanyDelete')!)) return;

        const value = window.prompt(t('typeDeleteToDelete')!);
        if (value?.toLowerCase() !== 'delete') return;

        try {
            await companyDetailsStore.deleteCompany(companyId);
            toast(t('companyDeleted'), { type: 'success' });
            authStore.logout();
        }
        catch (error) {
            toast(getErrorMessage(error), { type: 'error' });
        }
    }

    return (
        <div className='text-right'>
            <button
                onClick={() => onDelete(props.companyId)}
                className='underline text-gray-400'
                type='button'>
                {t('delete')} {t('company').toLowerCase()}
            </button>
        </div>
    )

}

export default DeleteCompany;