import Button from 'components/Forms/Button';
import FormSubmissionsTable from 'components/FormSubmissionsTable';
import TitleWithBackArrow from 'components/TitleWithBackArrow';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { authStore } from 'stores/AuthStore';
import { formSubmissionStore } from 'stores/FormSubmissionStore';

const PageFormSubmissions = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const downloadFormSubmissions = async (formId: string) => {
        const downloadToken = await authStore.getDownloadToken() as string;
        formSubmissionStore.exportSubmissions(formId, downloadToken);
    }

    return (
        <div>
            <div className='flex mt-3 mb-8 items-center'>
                <TitleWithBackArrow title={t('pdfReports')} />
                <div className='text-right flex items-center'>
                    <Button
                        appearance='secondary'
                        type='button'
                        onClick={() => downloadFormSubmissions(id!)}
                        label={t('downloadExcelFile')}
                    />
                </div>
            </div>
            <FormSubmissionsTable
                formId={id!}
            />
        </div>
    )

}

export default PageFormSubmissions;