import { useTranslation } from 'react-i18next';
import { FiLogOut } from 'react-icons/fi';
import { authStore } from 'stores/AuthStore';
import { PropsWithChildren } from 'react';

const SidebarMenu = (props: PropsWithChildren) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className='w-72 bg-white p-4 rounded shadow'>
                <div className=''>
                    <ul>
                        {props.children}
                    </ul>
                </div>
            </div>
            <div>
                <div
                    onClick={() => authStore.logout()}
                    className='
                    flex items-center 
                    p-4 
                    bg-gray-200 hover:bg-red-100
                    mt-4 
                    rounded 
                    text-gray-600 
                    cursor-pointer
                    '>
                    <p className='pl-4 flex items-center'>
                        <FiLogOut className='mr-2' />
                        <span>{t('logOut')}</span>
                    </p>
                </div>
            </div>
        </div>

    )

}

export default SidebarMenu;