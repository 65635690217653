import Checkbox from 'components/Forms/Checkbox';
import Input from 'components/Forms/Input';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from 'stores/AuthStore';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import { SchemaFormEnum } from 'types/SchemaFormEnum';

interface Props {
    visible?: boolean
}

const FormSettings = observer((props: Props) => {
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const { t } = useTranslation();

    return (
        <div className='mt-4'>
            <button
                type='button'
                onClick={() => setSettingsExpanded(!settingsExpanded)}
                className='
                            bg-gray-200 
                            py-1 px-4 
                            rounded 
                            text-sm'>
                {t(settingsExpanded ? 'hideSettings' : 'settings')}
            </button>
            <div className='mt-4 mb-12 bg-gray-50 border p-8 rounded' style={{ display: settingsExpanded ? 'block' : 'none' }}>

                <div className='flex'>
                    <div className='w-[200px]'>
                        <Checkbox name={SchemaFormEnum.area} label={t('area')} />
                    </div>
                    <Checkbox name={SchemaFormEnum.areaRequired} label={t('required')} />
                </div>

                <div className='flex'>
                    <div className='w-[200px]'>
                        <Checkbox name={SchemaFormEnum.section} label={t('section')} />
                    </div>
                    <Checkbox name={SchemaFormEnum.sectionRequired} label={t('required')} />
                </div>

                <Checkbox name={SchemaFormEnum.preliminaryNoteField} label={t('introductoryComments')} />
                <Checkbox name={SchemaFormEnum.smileys} label={t('smileys')} />
                <Checkbox name={SchemaFormEnum.notes} label={t('notes')} />

                <div className='flex'>
                    <div className='w-[200px]'>
                        <Checkbox name={SchemaFormEnum.photos} label={t('photos')} />
                    </div>
                    <Checkbox name={SchemaFormEnum.photosGPS} label={t('showGPSinReports')} />
                </div>
                {
                    selectedCompanyStore.loggedInAsSuperAdmin &&
                    <Checkbox name={SchemaFormEnum.isDemoTemplate} label={t('demo')} />
                }

                {
                    !selectedCompanyStore.loggedInAsSuperAdmin &&
                    <>
                        <Input
                            name={SchemaFormEnum.emails}
                            label='E-mails'
                            placeholder='email1@example.com, email2@example.com'
                            className='mt-6'
                        />
                        <div className='text-sm whitespace-pre-line mt-8'>
                            {t('settingsDescription')}
                        </div>
                    </>
                }
            </div>
        </div>

    )
})

export default FormSettings;