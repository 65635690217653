import { action, makeObservable, observable } from 'mobx';
import { FormDetailsInterface } from 'types/FormDetailsInterface';
import { FormListInterface } from 'types/FormListInterface';
import { BaseStore } from './BaseStore';

class TemplateStore extends BaseStore {

    forms: Array<FormListInterface> = [];
    fullForm: FormDetailsInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            forms: observable,
            fullForm: observable,

            resetStore: action,
            getFormTemplates: action,
            getFullForm: action
        })
    }

    resetStore = () => {
        this.forms = [];
    }

    getFormTemplates = () => {
        return this.get(`forms?byistemplate=true&includedrafts=false`, 'forms')
    }

    getFullForm = (formId: string) => {
        return this.get(`forms/${formId}/full`, 'fullForm')
    }

}

export const templateStore = new TemplateStore();