import BackToFrontpageButton from 'components/BackToFrontpageButton';
import { getErrorMessage } from 'helpers/ErrorHelper';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';

const PageNoAdminCompanies = () => {
    const { t } = useTranslation();

    const createNewCompany = async () => {
        const companyName = window.prompt(t('companyName')!);
        if (!companyName) return;

        try {
            await authStore.createCompany(companyName, false);
            authStore.logout();
            toast(t('signInAgainToAccessCompany'), { type: 'success' });
        }
        catch (error) {
            toast(getErrorMessage(error), { type: 'error' });
        }
    }

    return (
        <div className='
        mx-auto 
        w-full 
        p-12 
        lg:p-0 
        lg:w-96 
        mt-32
        '>
            <div className='bg-white px-8 py-16 rounded shadow-lg'>
                <h1 className='text-2xl mb-12 pt-4'>
                    {t('downloadAppToGetStarted')}
                </h1>
                <p className='whitespace-pre-line'>
                    {t('downloadAppDescription')}.
                </p>
                <div className='mt-8'>
                    <button
                        onClick={createNewCompany}
                        className='underline text-gray-400'
                        type='button'>
                        {t('createNewCompany')}
                    </button>
                </div>
            </div>
            <BackToFrontpageButton />
        </div>
    )

}

export default PageNoAdminCompanies;