import { FiChevronsUp } from 'react-icons/fi';
import { FiChevronsDown } from 'react-icons/fi';

export enum ArrowButtonDirection {
    UP,
    DOWN
}

interface Props {
    direction: ArrowButtonDirection,
    disabled?: boolean,
    className?: string,
    onClick?: () => void
}

const ArrowButton = (props: Props) => {

    let className = 'p-2 rounded';

    if (props.disabled) {
        className += ' bg-gray-100 text-gray-400';
    }
    else {
        className += ' bg-gray-200 text-gray-900 cursor-pointer';
    }

    if (props.direction === ArrowButtonDirection.UP) {
        return (
            <FiChevronsUp
                onClick={props.onClick}
                className={`${className} ${props.className ? props.className : ''}`} size={32} strokeWidth={1} />
        )
    }
    else {
        return (
            <FiChevronsDown
                onClick={props.onClick}
                className={`${className} ${props.className ? props.className : ''}`} size={32} strokeWidth={1} />
        )
    }
}

export default ArrowButton;