import Table from 'components/Table';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formsStore } from 'stores/FormsStore';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import _ from 'lodash';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'components/Routes';
import Actions from './Actions';

const FormsTable = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { selectedCompany } = selectedCompanyStore;

    useEffect(() => {
        if (selectedCompany) {
            formsStore.getForms(selectedCompany.id);
        }

        return () => {
            formsStore.resetStore();
        }
    }, [selectedCompany])


    const forms = _.orderBy(formsStore.forms, f => f.title?.toLowerCase());

    return (
        <Table
            defaultSortLabel='title'
            data={forms}
            onRowClicked={(item) => navigate(generatePath(routes.pageFormDetails, { id: item.id }))}
            render={[
                {
                    label: t('form'),
                    cell: (item) => item.title,
                    sortProperty: (item) => item.title
                },
                {
                    label: t('status'),
                    cell: (item) => item.isDraft ? t('draft') : t('published'),
                    sortProperty: (item) => item.isDraft ? t('draft') : t('published'),
                    width: 140
                },
                {
                    label: t('modified'),
                    cell: (item) => moment(item.modifiedOn || item.createdOn).format('DD-MM-YY'),
                    sortProperty: (item) => moment(item.modifiedOn || item.createdOn).format('DD-MM-YY'),
                    width: 110
                },
                {
                    label: '',
                    cell: (item) => <Actions item={item} />,
                    width: '160'
                }
            ]}
        />
    )

})

export default FormsTable;