import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { languageStore } from 'stores/LanguageStore';

const HideLanguageToggle = () => {

    useEffect(() => {
        // Forms are dependent on the language selected
        // To make sure users don't get confused, 
        // hide the languageSwitcher any time this form is visible
        languageStore.setLanguageSwitchVisible(false);

        return () => {
            languageStore.setLanguageSwitchVisible(true);
        }
    }, [])

    return <Outlet />

}

export default HideLanguageToggle;