import { action, makeObservable, observable } from 'mobx';
import { FormListInterface } from 'types/FormListInterface';
import { BaseStore } from './BaseStore';

class FormsStore extends BaseStore {

    forms: Array<FormListInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            forms: observable,

            resetStore: action,
            getSuperAdminForms: action,
            getForms: action
        })
    }

    resetStore = () => {
        this.forms = [];
    }

    getSuperAdminForms = () => {
        return this.get(`forms?byistemplate=true&includedrafts=true`, 'forms')
    }

    getForms = (companyId: string) => {
        return this.get(`forms?byCompanyId=${companyId}&includedrafts=true`, 'forms');
    }

}

export const formsStore = new FormsStore();