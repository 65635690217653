import CompanyName from './CompanyName';
import LogoImg from './logo.svg';

const Header = () => {
    return (
        <div
            style={{ marginLeft: '30px' }}
            className='
            flex 
            justify-between 
            py-4
            '>
            <div
                className='
                flex 
                items-center
                mb-4
            '>
                <img
                    src={LogoImg}
                    alt='Safety Observer app'
                    width={30}
                />
                <h1 className='
                    ml-2 
                    text-lg
                    text-gray-800
                '>
                    Safety Observer app 3.0
                </h1>
            </div>
            <div>
                <CompanyName />
            </div>
        </div>
    )
}

export default Header;