import _, { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { createRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { companyCategoriesStore } from 'stores/CompanyCategoriesStore';
import { formsStore } from 'stores/FormsStore';
import { selectedCompanyStore } from 'stores/SelectedCompanyStore';
import { templateStore } from 'stores/TemplateStore';
import { CategoryDto } from 'types/CategoryDto';
import { FormListInterface } from 'types/FormListInterface';
import { FormSectionInterface } from 'types/FormSectionInterface';
import { SchemaFormEnum } from 'types/SchemaFormEnum';

enum CategoryEnum {
    COMPANY_FORMS = "COMPANY_FORMS"
}

const LoadTemplate = observer(() => {
    const [templateValue, setTemplateValue] = useState<string>("");
    const [categories, setCategories] = useState<Array<CategoryDto>>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
    const { t } = useTranslation();
    const selectRef = createRef<any>();
    const selectedCompanyId = selectedCompanyStore.selectedCompany?.id;

    const { reset, getValues } = useFormContext();

    useEffect(() => {
        const getTemplates = async () => {

            const templates = await templateStore.getFormTemplates() as Array<FormListInterface>;
            const companyCategories = await companyCategoriesStore.getCategories(selectedCompanyStore.selectedCompany!.id) as Array<CategoryDto>;
            let categories = _.uniqBy(templates.map((t) => t.category), t => t?.id) as Array<CategoryDto>;

            if (companyCategories.length > 0) {
                categories = categories.filter(c => companyCategories.some(cc => cc.id === c?.id));
            }

            setCategories(categories);
        }

        getTemplates();

        return () => {
            templateStore.resetStore();
        }
    }, [])

    useEffect(() => {
        setTemplateValue("");
    }, [selectedCategoryId])

    useEffect(() => {
        if (selectedCompanyId) {
            formsStore.getForms(selectedCompanyId);

            return () => {
                formsStore.resetStore();
            }
        }
    }, [selectedCompanyId])

    const onLoadTemplate = async (forms: Array<FormListInterface>) => {
        const formId = selectRef.current.value;
        if (!formId) return;

        const form = await templateStore.getFullForm(formId);
        const values = getValues();
        const existingSections = values.sections as Array<FormSectionInterface>;
        const templateSections = orderBy(form.sections, s => s.position).map((section) => ({
            // id: section.id,
            title: section.title,
            description: section.description,
            items: orderBy(section.items, i => i.position).map((item) => ({
                id: item.id,
                title: item.title
            }))
        }))

        if (existingSections.length === 1) {
            const firstSection = existingSections[0];
            if (firstSection.title || firstSection.description) {
                reset({
                    ...values,
                    sections: [...existingSections, ...templateSections]
                })
            }
            else {
                reset({
                    ...values,
                    sections: templateSections
                })
            }
        }
        else {
            reset({
                ...values,
                sections: [...existingSections, ...templateSections]
            })
        }

        toast(t('templateInserted'), { type: 'success' });
    }

    const onChange = (newValue: string) => {
        setSelectedCategoryId(newValue);
        setTemplateValue(newValue)
    }

    let templates = templateStore.forms?.filter((f) => !selectedCategoryId ? f.category === null : f.category?.id === selectedCategoryId);

    const presentFormId = getValues(SchemaFormEnum.id);
    let forms: Array<FormListInterface> = [];
    
    if (selectedCategoryId === CategoryEnum.COMPANY_FORMS) {
        forms = formsStore.forms.filter((f) => (
            presentFormId ? f.id !== presentFormId : true // Dont load the form you are viewing right now be on the templates list
        ));
    }

    forms = _.orderBy([...templates, ...forms], t => t.title);

    return (
        <div className='flex-1 justify-end text-right mt-2 grid grid-cols-3 items-center gap-3'>
            <select className='flex-1 border px-2 py-1 text-sm rounded' onChange={(ev) => onChange(ev.currentTarget.value)}>
                <option value="">{t('selectCategory')}</option>
                <option value={CategoryEnum.COMPANY_FORMS}>-- {t('ownCompanyTemplates')} --</option>
                {
                    _.orderBy(categories, c => c?.name?.toLowerCase()).map((cat, index) => {
                        if (!cat) return null;

                        return (
                            <option key={index} value={cat.id}>
                                {cat.name}
                            </option>
                        )
                    })
                }
            </select>
            <select
                className='flex-1 border px-2 py-1 text-sm rounded'
                ref={selectRef}
                name='template'
                value={templateValue}
                onChange={e => setTemplateValue(e.currentTarget.value)}
                id='template'>

                <option value=''>-- {t('blankTemplate')} --</option>

                {
                    _.orderBy(forms, f => f.title?.toLowerCase()).map((form, index) => {
                        return (
                            <option key={index} value={form.id}>
                                {form.title}
                            </option>
                        )
                    })
                }
            </select>
            <button
                className='bg-gray-200 text-gray-700 ml-2 text-sm px-4 py-1 rounded'
                type='button'
                onClick={() => onLoadTemplate(forms)}>
                {t('loadTemplate')}
            </button>
        </div>
    )

})

export default LoadTemplate;